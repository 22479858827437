import axios, { AxiosResponse, Method } from "axios";

import { ApiPath, NETWORK_ERROR, StatusCode } from "./config";
import {
  FetchApiOptions,
  GetFetchApiData,
  GetFetchApiMethod,
  GetFetchApiPathData,
  GetFetchApiResponse,
} from "./types";
import { FrontendResponse } from "./types/response";
import { composeFormData, getToken, stringTemplate } from "./utils";

axios.defaults.headers["Pragma"] = "no-cache";

/**
 * Функция для выполнения API-запросов.
 * Для путей нужно использовать `ApiPath` перечисление
 *
 * @template P - тип пути API.
 * @template M - тип метода API.
 *
 * @param {P} path - путь API. В путь могут подставляться значения из config.pathData см. пример ниже.
 * @param {M} method - метод API.
 * @param {object} config - дополнительная конфигурация для axios.
 * @param {object} config.data - данные для запроса. Матчится в зависимости от пути и метода.
 * @param {object} config.pathData - данные для path. Подставляются данные из объекта в путь см. пример ниже.
 * Например `/api/user/{id}/get`
 *
 * @returns {Promise} - промис с ответом от сервера.
 *
 * @example Пример запроса
 * fetchApi(ApiPath.USER_CURRENT, 'GET');
 *
 * @example Пример работы pathData
 * fetchApi('/api/user/{id}/get', 'GET', { pathData: {id: 10 }});
 * Получим запрос на path '/api/user/10/get'
 */
function fetchApi<P extends ApiPath, M extends GetFetchApiMethod<P>, R = any>(
  path: P,
  method: M,
  {
    withToken = false,
    multipartFormData = false,
    cacheForceUpdate = false,
    hhrFrontName = "HHR",
    pathData = {} as any,
    data = {} as any,
    ...axiosConfig
  }: FetchApiOptions<GetFetchApiPathData<P, M>, GetFetchApiData<P, M>> = {},
): Promise<FrontendResponse<GetFetchApiResponse<P, M, R>>> {
  let dataEnded: any = data;

  const pathWithData = stringTemplate(
    path,
    pathData as Record<string, unknown>,
  );

  if (!axiosConfig.headers) {
    axiosConfig.headers = {};
  }

  if (String(method).toLocaleLowerCase() === "get") {
    axiosConfig.params = data;
    dataEnded = null;
  } else if (multipartFormData) {
    const [formData, formDataHeaders] = composeFormData(dataEnded as object);

    dataEnded = formData;
    axiosConfig.headers = { ...axiosConfig.headers, ...formDataHeaders };
  }

  if (cacheForceUpdate) {
    axiosConfig.headers["Cache-Force-Update"] = "True";
  }

  if (withToken) {
    axiosConfig.headers.Authorization = `Bearer ${getToken()}`;
  }

  axiosConfig.headers["X-HHR-FRONT-NAME"] = hhrFrontName;

  return axios({
    method: method as Method,
    url: pathWithData,
    data: dataEnded,
    ...axiosConfig,
  })
    .then((response: AxiosResponse) => ({ response }))
    .catch((error) => {
      if (!error.response) {
        error.response = {
          status: StatusCode.SERVER_ERROR_500,
          statusText: NETWORK_ERROR,
          data: null,
          headers: {},
          config: {},
        };
      }

      return error;
    });
}

export default fetchApi;

import { StoryType } from "@repo/data/store/single/GlobalStoriesStore";

import image1 from "./assets/1.png";
import image2 from "./assets/2.png";
import image3 from "./assets/3.png";
import image4 from "./assets/4.png";
import cover from "./assets/cover.png";

export const WIDGET_CAROUSEL_HISTORY_CONFIG: StoryType[] = [
  {
    id: 1,
    title: <>Иванов Олег&nbsp;- день рождения 5&nbsp;июля</>,
    description: (
      <>Ознакомьтесь с&nbsp;новым регламентом продаж в&nbsp;этом месяце</>
    ),
    highlight: true,
    image: image1,
    cover,
    link: "https://hellohr.ru/",
  },
  {
    id: 2,
    title: <>Изменения в&nbsp;корп.&nbsp;программах</>,
    description: (
      <>Ознакомьтесь с&nbsp;новым регламентом продаж в&nbsp;этом месяце</>
    ),
    image: image2,
    cover,
    link: "https://hellohr.ru/",
  },
  {
    id: 3,
    title: <>Дайджест новостей за&nbsp;неделю</>,
    description: (
      <>Ознакомьтесь с&nbsp;новым регламентом продаж в&nbsp;этом месяце</>
    ),
    image: image3,
    cover,
    link: "https://hellohr.ru/",
  },
  {
    id: 4,
    title: <>Обновление регламента&nbsp;продаж</>,
    description: (
      <>Ознакомьтесь с&nbsp;новым регламентом продаж в&nbsp;этом месяце</>
    ),
    image: image3,
    cover,
  },
  {
    id: 5,
    title: <>Обновление регламента&nbsp;продаж</>,
    description: (
      <>Ознакомьтесь с&nbsp;новым регламентом продаж в&nbsp;этом месяце</>
    ),
    image: image4,
    cover,
  },
  {
    id: 6,
    title: <>Обновление регламента&nbsp;продаж</>,
    description: (
      <>Ознакомьтесь с&nbsp;новым регламентом продаж в&nbsp;этом месяце</>
    ),
    image: image4,
    cover,
  },
  {
    id: 7,
    title: <>Обновление регламента&nbsp;продаж</>,
    description: (
      <>Ознакомьтесь с&nbsp;новым регламентом продаж в&nbsp;этом месяце</>
    ),
    image: image4,
    cover,
  },
  {
    id: 8,
    title: <>Обновление регламента&nbsp;продаж</>,
    description: (
      <>Ознакомьтесь с&nbsp;новым регламентом продаж в&nbsp;этом месяце</>
    ),
    image: image4,
    cover,
  },
  {
    id: 9,
    title: <>Обновление регламента&nbsp;продаж</>,
    description: (
      <>Ознакомьтесь с&nbsp;новым регламентом продаж в&nbsp;этом месяце</>
    ),
    image: image4,
    cover,
  },
  {
    id: 10,
    title: <>Обновление регламента&nbsp;продаж</>,
    description: (
      <>Ознакомьтесь с&nbsp;новым регламентом продаж в&nbsp;этом месяце</>
    ),
    image: image4,
    cover,
  },
];

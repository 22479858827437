import clsx from "clsx";
import { memo, useCallback } from "react";

import Button from "@repo/ui/Button";

import { TagAngle, TagColor, TagSize } from "./config";

import * as s from "./Tag.module.scss";

export type TagProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Текст лейбла. */
  label: string;
  /** Значение тега. */
  value?: string;
  /** Размер тега. */
  size: TagSize;
  /** Цвет тега. */
  color: TagColor;
  /** Угол без скругления. */
  angle?: TagAngle;
  /** Обработчик клика по тегу. */
  onClick?: (value: string) => void;
};

const Tag: React.FC<TagProps> = ({
  className,
  label,
  value,
  size,
  color,
  angle,
  onClick,
  ...props
}: TagProps) => {
  const classNames = clsx(
    s["tag"],
    s[`tag_size-${size}`],
    s[`tag_color-${color}`],
    s[`tag_angle-${angle}`],
    !onClick && s["tag_no-pointer"],
    className,
  );

  const handleClick = useCallback(() => {
    if (!value || !onClick) {
      return;
    }

    onClick(value);
  }, [value, onClick]);

  return (
    <Button.Default className={classNames} onClick={handleClick} {...props}>
      {label}
    </Button.Default>
  );
};

export default memo(Tag);

import { memo } from "react";

import IconBase, { type IconBaseProps } from "./IconBase";

const EditIcon: React.FC<IconBaseProps> = (props: IconBaseProps) => {
  return (
    <IconBase {...props} height="24" viewBox="0 0 24 24" width="24">
      <path
        d="m21.3113 6.87821-4.1897-4.18875c-.1393-.13932-.3047-.24984-.4867-.32525-.182-.0754-.3771-.11421-.5741-.11421-.197 0-.3921.03881-.5741.11421-.182.07541-.3474.18593-.4867.32525L3.43969 14.2498c-.13989.1388-.2508.304-.32628.486-.07549.182-.11403.3772-.1134.5743v4.1897c0 .3978.15803.7793.43934 1.0606.2813.2813.66283.4394 1.06066.4394H20.25c.1989 0 .3897-.079.5303-.2197.1407-.1406.2197-.3314.2197-.5303 0-.1989-.079-.3897-.2197-.5304-.1406-.1406-.3314-.2196-.5303-.2196h-9.4387l10.5-10.50003c.1393-.13929.2498-.30466.3252-.48667s.1142-.3771.1142-.57411c0-.19701-.0388-.39209-.1142-.5741-.0754-.18201-.1859-.34739-.3252-.48668ZM18 10.1895l-4.1887-4.18973 2.25-2.25L20.25 7.93946 18 10.1895Z"
        fill="currentColor"
      />
    </IconBase>
  );
};

export default memo(EditIcon);

import React, { memo, useCallback } from "react";

import Tag, { TagAngle, TagColor, TagSize } from "@repo/ui/Tag";

import * as s from "./TagsList.module.scss";

type Props = {
  list: string[];
  setValue: (value: string) => void;
};

const TagsList: React.FC<Props> = ({ list, setValue }) => {
  const handleClick = useCallback(
    (label: string) => {
      setValue(label);
    },
    [setValue],
  );

  return (
    <div className={s["root"]}>
      {list.map((tag) => (
        <Tag
          key={tag}
          angle={TagAngle.TOP_LEFT}
          color={TagColor.DEFAULT}
          label={tag}
          size={TagSize.M}
          value={tag}
          onClick={handleClick}
        />
      ))}
    </div>
  );
};

export default memo(TagsList);

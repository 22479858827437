import {
  autoUpdate,
  flip,
  offset,
  type Placement,
  shift,
  useClick,
  useDismiss,
  useFloating,
  type UseFloatingOptions,
  useHover,
  useInteractions,
} from "@floating-ui/react";
import { useMemo, useState } from "react";

export type UsePopoverOptions = {
  /** Причина открытия поповера. */
  trigger?: "click" | "hover";
  /** Начальное состояние поповера. */
  initialOpen?: boolean;
  /** Является ли поповер модальным. */
  modal?: boolean;
  /** Указывает, нужно ли оборачивать содержимое в FloatingOverlay. */
  withOverlay?: boolean;
  /** Класс оверлея. */
  classNameOverlay?: string;
  /** Положение поповера. */
  placement?: Placement;
  /** Смещение содержимого поповера. */
  offsetContent?: number;
} & UseFloatingOptions;

export function usePopover({
  trigger = "click",
  initialOpen = false,
  modal = false,
  withOverlay = false,
  classNameOverlay,
  placement = "bottom",
  offsetContent,
  ...props
}: UsePopoverOptions) {
  const [open, setOpen] = useState<boolean>(initialOpen);

  const data = useFloating({
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    placement,
    middleware: [
      offset(offsetContent),
      flip({
        crossAxis: placement.includes("-"),
        fallbackAxisSideDirection: "end",
        padding: offsetContent,
      }),
      shift({ padding: offsetContent }),
    ],
    ...props,
  });

  const { context } = data;

  const click = useClick(context, {
    enabled: trigger === "click",
  });

  const hover = useHover(context, {
    enabled: trigger === "hover",
  });

  const dismiss = useDismiss(context);
  const interactions = useInteractions([click, hover, dismiss]);

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      modal,
      withOverlay,
      classNameOverlay,
    }),
    [open, interactions, data, modal, withOverlay, classNameOverlay],
  );
}

import { client } from "@repo/api/types";

import S3ResourceModel from "../../models/S3ResourceModel";

import { SettingsType } from "./types";

export const normalizeSettings = (
  data: client.ClientItemApi,
): SettingsType => ({
  welcomeImage: new S3ResourceModel(data.welcome_image),
  systemName: data.name,
  companyName: data.company_name,
});

import { memo } from "react";

import IconBase, { type IconBaseProps } from "./IconBase";

const IconDoubleCheck: React.FC<IconBaseProps> = (props: IconBaseProps) => {
  return (
    <IconBase {...props} height="16" viewBox="0 0 16 16" width="16">
      <path
        d="m9.526 5.535-5.6 5.5a.75.75 0 0 1-1.052 0l-2.4-2.357a.75.75 0 0 1 1.052-1.07L3.4 9.447l5.074-4.983a.75.75 0 0 1 1.051 1.07Zm6.009-1.063a.75.75 0 0 0-1.063-.009L9.4 9.449l-.493-.484a.75.75 0 0 0-1.05 1.07l1.017 1a.75.75 0 0 0 1.052 0l5.6-5.5a.75.75 0 0 0 .009-1.06v-.003Z"
        fill="currentColor"
      />
    </IconBase>
  );
};

export default memo(IconDoubleCheck);

import clsx from "clsx";
import React, { memo, useCallback, useRef, useState } from "react";

import Button from "@repo/ui/Button";
import Icon, { IconType } from "@repo/ui/Icon";

import * as s from "./InputSearch.module.scss";

type Props = {
  /** Дополнительные CSS классы для компонента. */
  className?: string;
  /** Значение поля ввода. */
  value: string;
  /** Обработчик изменения значения поля ввода. */
  onChange: (value: string) => void;
  /** Callback для клика по кнопке поиска */
  onSearch: () => void;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "value" | "onChange">;

const InputSearch: React.FC<Props> = ({
  className,
  value,
  onChange,
  placeholder = "Что вас интересует ?",
  onSearch,
  ...props
}: Props) => {
  const ref = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!onChange) {
        return;
      }

      onChange(e.target.value);
    },
    [onChange],
  );

  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleClick = useCallback<
    React.MouseEventHandler<HTMLDivElement>
  >(() => {
    if (!ref.current || isFocused) {
      return;
    }

    ref.current.focus();
    setIsFocused(true);
  }, [isFocused]);

  const handleSearch = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      onSearch();
    },
    [onSearch],
  );

  return (
    <div
      className={clsx(className, s["root"])}
      onBlur={handleBlur}
      onClick={handleClick}
    >
      <Icon className={s["root__icon"]} type={IconType.SEARCH} />
      <input
        ref={ref}
        className={clsx(s["root__field"])}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        {...props}
      />
      <Button.Default className={s["root__button"]} onClick={handleSearch}>
        <Icon type={IconType.ARROW_RIGHT} />
      </Button.Default>
    </div>
  );
};

InputSearch.displayName = "InputSearch";

export default memo(InputSearch);

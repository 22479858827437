import { useRef } from "react";

/**
 * Хук `useLocal` создает и сохраняет локальное состояние с помощью `useRef`.
 *
 * @template T Тип состояния.
 * @param {() => T} creator Функция, создающая начальное состояние.
 * @returns {T} Возвращает текущее состояние.
 */
const useLocal = <T>(creator: () => T): T => {
  const local = useRef<T | null>(null);

  if (local.current === null) {
    local.current = creator();
  }

  return local.current;
};

export default useLocal;

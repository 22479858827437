import {
  fetchApi,
  isBackendErrorResponse,
  isBackendOkResponse,
  isNetworkErrorResponse,
  isNetworkOkResponse,
  StatusCode,
} from "../../fetchApi";
import { ApiWitChecksResponse, MiddlewareParams } from "../types";

async function callFetchApi(
  params: MiddlewareParams,
): Promise<ApiWitChecksResponse> {
  const fetch = await fetchApi(params.path, params.method, params.options);

  if (!fetch.response) {
    return {
      isError: true,
      isNotAuthorized: false,
      data: null,
      errorData: null,
      rawResponse: null,
    };
  }

  const { response } = fetch;

  /**
   * Положительный HTTP ответ
   */
  if (isNetworkOkResponse(response)) {
    return {
      isError: false,
      /**
       * Этот ответ соответствует структуре нашего бэкенда
       */
      data: isBackendOkResponse(response) ? response.data.data : null,
      errorData: null,
      rawResponse: response,
    };
  }

  /**
   * HTTP ответ с ошибкой
   */
  if (isNetworkErrorResponse(response)) {
    /**
     * Ответ соответствует структуре ответа нашего бэкенда
     */
    if (isBackendErrorResponse(response)) {
      return {
        isError: true,
        isNotAuthorized: response?.status === StatusCode.UNAUTHORIZED_401,
        data: null,
        errorData: response.data.data,
        code: response.data.code,
        message: response.data.message,
        rawResponse: response,
      };
    }

    return {
      isError: true,
      isNotAuthorized: false,
      data: null,
      errorData: null,
      rawResponse: response,
    };
  }

  /**
   * Произошло что-то совсем неизвестное
   */
  return {
    isError: true,
    isNotAuthorized: false,
    data: null,
    errorData: null,
    rawResponse: response,
  };
}

export default callFetchApi;

import WithError, { type WithErrorProps } from "../WithError";

function withError<
  C extends React.ComponentType<any>,
  P extends React.ComponentProps<C>,
>(Component: C & React.ComponentType<{ error?: boolean }>) {
  const WrappedComponent: React.FC<P & WithErrorProps> = ({
    message,
    ...props
  }: P & WithErrorProps) => {
    return (
      <WithError message={message}>
        <Component error={Boolean(message)} {...(props as P)} />
      </WithError>
    );
  };

  WrappedComponent.displayName = `withError(${Component.displayName})`;

  return WrappedComponent;
}

export default withError;

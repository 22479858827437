import { action, makeObservable, observable } from "mobx";

import LocalStorage from "@repo/data/storage";
import { ILocalStore } from "@repo/data/store/interfaces/ILocalStore";

type PrivateFields = "_tabs";

class CollapseStore implements ILocalStore {
  private static _STORAGE_KEY = "collapse-tabs";

  private readonly _tabs = new Set<string>();

  constructor() {
    const localData = LocalStorage.get(CollapseStore._STORAGE_KEY, {
      defaultValue: "",
    });

    if (localData) {
      this._tabs = new Set(JSON.parse(localData));
    }

    makeObservable<this, PrivateFields>(this, {
      _tabs: observable,

      toggleTab: action.bound,
    });
  }

  isTabOpened(id: string): boolean {
    return this._tabs.has(id);
  }

  toggleTab(id: string): void {
    if (this.isTabOpened(id)) {
      this._tabs.delete(id);
    } else {
      this._tabs.add(id);
    }

    LocalStorage.set(
      CollapseStore._STORAGE_KEY,
      JSON.stringify(Array.from(this._tabs)),
    );
  }

  destroy(): void {
    // do nothing
  }
}

export default CollapseStore;

/* eslint-disable @typescript-eslint/ban-ts-comment */

type SingleStore = {
  destroyInstance(): void;
  getInstance(): unknown;
};

class SingleStoreManager {
  private static _stores: SingleStore[] = [];
  private static _destroyedStores: SingleStore[] = [];

  private constructor() {}

  // Игнорим S["prototype"] потому что в TS нет возможности получить тип прототипа класса,
  // но это работает!!!
  /**
   * Создания стора-одиночки
   * @param IncomingStore - Класс стора
   * @param options - Опции
   * @returns - Экземпляр стора
   */
  static createStore<
    S extends { new (...args: unknown[]): I },
    // @ts-expect-error
    I extends S["prototype"],
  >(IncomingStore: S, options: { destroyed?: boolean } = {}) {
    // @ts-expect-error
    class Store extends IncomingStore {
      private static _instance: Store | null = null;

      private constructor(...args: unknown[]) {
        super(...args);
      }

      /**
       * Получение экземпляра стора
       *
       * @returns - Экземпляр стора
       */
      static getInstance(...args: Partial<ConstructorParameters<S>>): Store {
        if (!this._instance) {
          this._instance = new Store(...args);
        }

        return this._instance;
      }

      /**
       * Уничтожение экземпляра стора
       */
      static destroyInstance(): void {
        this._instance = null;
      }
    }

    this._stores.push(Store);
    if (options.destroyed) {
      this._destroyedStores.push(Store);
    }

    return Store;
  }

  /**
   * Уничтожение сторов, у которых опция destroyed установлена в true
   */
  static destroyStores(): void {
    this._destroyedStores.forEach((store) => {
      store.destroyInstance();
    });
  }

  /**
   * Уничтожение всех сторов
   */
  static destroyAllStores(): void {
    this._stores.forEach((store) => {
      store.destroyInstance();
    });
  }
}

export default SingleStoreManager;

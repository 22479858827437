import { action, makeObservable, observable } from "mobx";

import { DeviceBreakpoint } from "../../../config/breakpoint";

class UIStore {
  private _width: number = 0;

  constructor() {
    makeObservable<this, "_width">(this, {
      _width: observable,
      setWidth: action.bound,
    });
  }

  get isPhone(): boolean {
    return this._width <= DeviceBreakpoint.PHONE;
  }

  get isTablet(): boolean {
    return (
      this._width > DeviceBreakpoint.PHONE &&
      this._width <= DeviceBreakpoint.TABLET
    );
  }

  get isDesktop(): boolean {
    return this._width > DeviceBreakpoint.TABLET;
  }

  setWidth(width: number): void {
    this._width = width;
  }
}

export default UIStore;

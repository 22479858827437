// extracted by mini-css-extract-plugin
var _1 = "login-page__umzbO";
var _2 = "login-page__code__tyib8";
var _3 = "login-page__column__VaQUW";
var _4 = "login-page__column_content__eXOoP";
var _5 = "login-page__column_image__AnIf_";
var _6 = "login-page__content__Ex2kc";
var _7 = "login-page__form__Gmi7o";
var _8 = "login-page__image__zdBxU";
var _9 = "login-page__logo__oGUl3";
export { _1 as "login-page", _2 as "login-page__code", _3 as "login-page__column", _4 as "login-page__column_content", _5 as "login-page__column_image", _6 as "login-page__content", _7 as "login-page__form", _8 as "login-page__image", _9 as "login-page__logo" }

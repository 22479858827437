import { memo } from "react";

import IconBase, { type IconBaseProps } from "./IconBase";

const BellIcon: React.FC<IconBaseProps> = (props: IconBaseProps) => {
  return (
    <IconBase {...props} height="24" viewBox="0 0 24 24" width="24">
      <path
        d="M19.6475 15.5851c-.4917-.8469-1.2225-3.243-1.2225-6.37259 0-1.87947-.7466-3.68195-2.0756-5.01094-1.3289-1.32898-3.1314-2.07559-5.0109-2.07559-1.87946 0-3.68195.74661-5.01093 2.07559-1.32898 1.32899-2.0756 3.13147-2.0756 5.01094 0 3.13049-.73168 5.52569-1.22331 6.37259-.12555.2153-.1921.4599-.19296.7091-.00085.2492.06402.4942.18809.7104.12406.2161.30293.3957.51856.5207.21563.125.4604.1909.70962.191h3.61502c.1635.8001.5983 1.5191 1.23087 2.0355.63257.5163 1.42404.7984 2.24064.7984s1.6081-.2821 2.2406-.7984c.6326-.5164 1.0674-1.2354 1.2309-2.0355h3.615c.2492-.0003.4938-.0663.7094-.1913.2155-.125.3942-.3047.5182-.5208.1239-.2161.1887-.4611.1878-.7102-.0009-.2492-.0674-.4937-.1929-.7089Zm-8.309 3.5486c-.4395-.0002-.8682-.1365-1.227-.3904-.35886-.2538-.6302-.6126-.77671-1.027h4.00741c-.1465.4144-.4178.7732-.7767 1.027-.3588.2539-.7875.3902-1.227.3904Z"
        fill="currentColor"
      />
    </IconBase>
  );
};

export default memo(BellIcon);

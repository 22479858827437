import clsx from "clsx";
import { memo, type PropsWithChildren } from "react";

import ButtonDefault, { ButtonDefaultProps, ButtonStyleProps } from "../Button";
import { ButtonColor, ButtonSize, ButtonTheme } from "../config";

import * as s from "./ButtonBase.module.scss";

export type ButtonBaseProps = ButtonDefaultProps & ButtonStyleProps;

const ButtonBase: React.FC<PropsWithChildren<ButtonBaseProps>> = ({
  className,
  size = ButtonSize.MEDIUM,
  color = ButtonColor.PRIMARY,
  theme = ButtonTheme.DEFAULT,
  disabled,
  children,
  ...props
}: PropsWithChildren<ButtonBaseProps>) => {
  const classNames = clsx(
    s["button"],
    s[`button_theme-${theme}`],
    s[`button_size-${size}`],
    s[`button_color-${color}`],
    disabled && s["button_disabled"],
    className,
  );

  return (
    <ButtonDefault className={classNames} disabled={disabled} {...props}>
      {children}
    </ButtonDefault>
  );
};

export default memo(ButtonBase);

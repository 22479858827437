import { ApiPath, fetchApi } from "../../fetchApi";
import { EventApiWithChecksNotAuthorized } from "../event";
import { MiddlewareFunc } from "../types";

/**
 * Проверяет ошибку протухания токена
 */
const checkNotAuthorizedLayer: MiddlewareFunc = async (params, next) => {
  let response = await next(params);

  if (params.path === ApiPath.USER_LOGOUT) {
    return response;
  }

  if (response.isError && response.isNotAuthorized) {
    try {
      await fetchApi(ApiPath.USER_REFRESH, "GET", {});
      response = await next(params);
    } catch (e) {
      document.dispatchEvent(new EventApiWithChecksNotAuthorized());
    }
  }

  return response;
};

export default checkNotAuthorizedLayer;

import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useCallback } from "react";

import { Theme } from "@repo/data/config/theme";
import GlobalThemeStore from "@repo/data/store/single/GlobalThemeStore";
import GlobalUserStore from "@repo/data/store/single/GlobalUserStore";
import Button, { ButtonSize } from "@repo/ui/Button";
import Icon, { IconType } from "@repo/ui/Icon";
import SwitchTheme from "@repo/ui/SwitchTheme";

import userAvatarImage from "@app/assets/user-avatar.jpg";

import Notifications from "./components/Notifications";
import ProfileLink from "./components/ProfileLink";

import * as s from "./Header.module.scss";

export type HeaderProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
};

const Header: React.FC<HeaderProps> = ({ className }: HeaderProps) => {
  const globalThemeStore = GlobalThemeStore.getInstance();
  const globalUserStore = GlobalUserStore.getInstance();

  const handelThemeChange = useCallback(() => {
    globalThemeStore.setTheme(
      globalThemeStore.isLight ? Theme.DARK : Theme.LIGHT,
    );
  }, [globalThemeStore]);

  return (
    <header className={clsx(s["header"], className)}>
      <Button.Base
        before={<Icon type={IconType.PLUS} />}
        className={s["header__request"]}
        size={ButtonSize.LARGE}
      >
        Создать заявку
      </Button.Base>

      <div className={s["header__controls"]}>
        <SwitchTheme
          checked={globalThemeStore.isLight}
          className={s["header__switch-theme"]}
          onChange={handelThemeChange}
        />

        <Notifications />
      </div>

      <ProfileLink
        avatar={globalUserStore.data.avatarUrl || userAvatarImage}
        username={globalUserStore.data.fullName}
      />
    </header>
  );
};

export default observer(Header);

import { action, makeObservable, observable, set } from "mobx";

import { UserType } from "./types";

type PrivateFields = "_data";

class UserStore {
  private _data: UserType;

  get data(): UserType {
    return this._data;
  }

  constructor(data: UserType) {
    this._data = data;

    makeObservable<this, PrivateFields>(this, {
      _data: observable,
      setAvatarUrl: action.bound,
    });
  }

  setAvatarUrl(value: string): void {
    set(this._data, { avatarUrl: value });
  }
}

export default UserStore;

import { memo, useEffect, useState } from "react";

import Popover, { TRANSITION_VARIANT } from "@repo/ui/Popover";

import useWidth from "@app/hooks/useWidth";

import NotificationBell from "./components/NotificationBell";
import NotificationContent from "./components/NotificationContent";

import * as s from "./Notifications.module.scss";

const Notifications: React.FC = () => {
  const { isPhone } = useWidth();
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => ++prevCount);
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [count]);

  return (
    <Popover.Base
      modal
      classNameOverlay={s["popover"]}
      offsetContent={22}
      withOverlay={isPhone}
    >
      <Popover.Trigger>
        {(props) => <NotificationBell count={count} {...props} />}
      </Popover.Trigger>

      <Popover.Content
        transition={
          isPhone
            ? TRANSITION_VARIANT.PHONE_WITH_OVERLAY
            : TRANSITION_VARIANT.DEFAULT
        }
      >
        <NotificationContent className={s["popover__content"]} count={count} />
        <Popover.Close />
      </Popover.Content>
    </Popover.Base>
  );
};

export default memo(Notifications);

import { memo } from "react";

import IconBase, { type IconBaseProps } from "./IconBase";

const MoonIcon: React.FC<IconBaseProps> = (props: IconBaseProps) => {
  return (
    <IconBase {...props} height="20" viewBox="0 0 20 20" width="20">
      <path
        d="M18.0334 10.8335c-.1202-.0994-.266-.163-.4207-.1837-.1547-.0206-.3121.0026-.4543.0671-.8811.4032-1.8392.6108-2.8083.6083-1.7926-.0022-3.5117-.713-4.78237-1.97741-1.2707-1.26445-1.98995-2.97999-2.00095-4.77258.0038-.56181.07373-1.12121.20833-1.66667.02857-.14538.01785-.29575-.03103-.43562-.04889-.13987-.13419-.26417-.2471-.3601-.11291-.09594-.24936-.16003-.39529-.18568-.14592-.02566-.29605-.01194-.43491.03973-1.3064.58743-2.44202 1.4973-3.3002 2.64416-.85818 1.14685-1.41073 2.49303-1.60576 3.91208-.19503 1.41905-.02612 2.86439.49085 4.20019.51697 1.3359 1.36504 2.5184 2.46453 3.4365 1.09949.918 2.41431 1.5415 3.82095 1.8119s2.85895.1787 4.22045-.2663c1.3615-.445 2.5875-1.2289 3.5628-2.2779.9754-1.049 1.6681-2.3287 2.013-3.719.0421-.1591.036-.3272-.0174-.4829-.0534-.1557-.1517-.2922-.2826-.3921Zm-7.9166 5.575c-1.39855-.0098-2.75985-.4517-3.89753-1.2652-1.13767-.8134-1.9961-1.9586-2.45775-3.2788-.46166-1.3202-.50396-2.7508-.12112-4.09596.38283-1.34515 1.17209-2.53908 2.25971-3.41833v.225c.00221 2.2404.89318 4.38841 2.47739 5.97259 1.5842 1.5842 3.7322 2.4752 5.9726 2.4774.5882.0022 1.175-.0593 1.75-.1833-.5751 1.0879-1.4363 1.9982-2.4907 2.6326-1.0544.6344-2.2621.9689-3.4926.9674v-.0334Z"
        fill="currentColor"
      />
    </IconBase>
  );
};

export default memo(MoonIcon);

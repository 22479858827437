export enum TypographyType {
  NUMBERS_LG = "numbers-lg",
  NUMBERS_MD = "numbers-md",
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  H4 = "h4",
  BODY_LG = "body-lg",
  BODY_MD = "body-md",
  BTN_MD = "btn-md",
  BODY_SM = "body-sm",
  BTN_SM = "btn-sm",
  CAPS_LG = "caps-lg",
  CAPS_MD = "caps-md",
  CAPS_SM = "caps-sm",
  CAPTION = "caption",
  CAPTION_SM = "caption-sm",
  CAPTION_EXTRA_SM = "caption-extra-sm",
}

export enum TypographyWeight {
  _100 = "100",
  _200 = "200",
  _300 = "300",
  _400 = "400",
  _500 = "500",
  _600 = "600",
  _700 = "700",
  _800 = "800",
  _900 = "900",
}

import { useObserver } from "mobx-react-lite";

import GlobalUIStore from "@repo/data/store/single/GlobalUIStore";

function useWidth() {
  const globalUIStore = GlobalUIStore.getInstance();

  return useObserver(() => ({
    isPhone: globalUIStore.isPhone,
    isTablet: globalUIStore.isTablet,
    isDesktop: globalUIStore.isDesktop,
  }));
}

export default useWidth;

import { AxiosResponse } from "axios";

import { LocalStorageKey } from "./config";
import { BackendErrorResponse, BackendResponse } from "./types/response";

/**
 * Создает объект FormData из переданных данных и возвращает его вместе с заголовками.
 *
 * @param data - Объект с данными для добавления в FormData.
 * @returns Возвращает массив, содержащий объект FormData и объект с заголовками.
 */
export function composeFormData(
  data: Record<string, any> = {},
): [FormData, Record<string, string>] {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(key, item);
      });
    } else {
      formData.append(key, value);
    }
  });

  return [
    formData,
    {
      "Content-Type": "multipart/form-data",
    },
  ];
}

/**
 * Получает токен доступа из локального хранилища.
 *
 * @returns {string|null} Возвращает токен доступа или null, если токен не найден.
 */
export function getToken(): string | null {
  return localStorage.getItem(LocalStorageKey.API_TOKEN);
}

/**
 * Функция для подстановки значений в строковый шаблон.
 *
 * @param {string} str - Исходная строка с местами для подстановки, обозначенными в фигурных скобках.
 * @param  data - Объект с данными для подстановки. Ключи объекта соответствуют местам подстановки в исходной строке.
 *
 * @returns {string} Результирующая строка после подстановки значений из объекта data.
 */
export function stringTemplate(
  str: string,
  data: Record<string, unknown>,
): string {
  return str.replaceAll(/\{([a-zA-Z0-9]+)\}/g, (match, key) =>
    key in data ? String(data[key]) : match,
  );
}

/**
 * Проверяет, является ли ответ сети успешным.
 *
 * @param resp - Ответ.
 * @returns {boolean} Возвращает true, если статус ответа меньше 400, иначе false.
 */
export function isNetworkOkResponse<T = any>(resp: AxiosResponse<T>): boolean {
  return resp && resp.status < 400;
}

/**
 * Проверяет, является ли ответ успешным и соответствует структуре ответа бэкенда.
 *
 * @param resp - Ответ.
 * @returns {boolean} Возвращает true, если статус ответа меньше 400 и статус данных равен 'ok', иначе false.
 */
export function isBackendOkResponse<T = any>(
  resp: AxiosResponse<BackendResponse<T>>,
): resp is AxiosResponse<BackendResponse<T>> {
  return isNetworkOkResponse(resp) && resp.data.status === "ok";
}

/**
 * Проверяет, является ли ответ сети ошибкой.
 *
 * @param resp - Ответ сети.
 * @returns {boolean} Возвращает true, если ответ не определен или статус ответа больше или равен 400, иначе false.
 */
export function isNetworkErrorResponse<T = any>(
  resp: AxiosResponse<T> | null | undefined,
): boolean {
  return !resp || resp.status >= 400;
}

/**
 * Проверяет, является ли ответ ошибкой и соответствует структуре ответа бэкенда.
 *
 * @param resp - Ответ.
 * @returns {boolean} Возвращает true, если статус ответа не является успешным и статус данных равен 'error', иначе false.
 */
export function isBackendErrorResponse<T = any>(
  resp: any,
): resp is AxiosResponse<BackendErrorResponse<T>> {
  return (
    !isNetworkOkResponse(resp) && resp.data && resp.data.status === "error"
  );
}

import { memo } from "react";

import IconBase, { type IconBaseProps } from "./IconBase";

const PhotoIcon: React.FC<IconBaseProps> = (props: IconBaseProps) => {
  return (
    <IconBase {...props} height="16" viewBox="0 0 16 16" width="16">
      <path
        d="M13 3.5h-1.7325l-.8519-1.2775c-.0456-.06841-.1074-.1245-.1799-.16331C10.1632 2.02038 10.0822 2.00005 10 2H6c-.08223.00005-.16318.02038-.23568.05919-.0725.03881-.13431.0949-.17995.16331L4.73187 3.5H3c-.39782 0-.77936.15804-1.06066.43934C1.65804 4.22064 1.5 4.60218 1.5 5v7c0 .3978.15804.7794.43934 1.0607S2.60218 13.5 3 13.5h10c.3978 0 .7794-.158 1.0607-.4393S14.5 12.3978 14.5 12V5c0-.39782-.158-.77936-.4393-1.06066C13.7794 3.65804 13.3978 3.5 13 3.5Zm-2.75 4.75c0 .44501-.132.88002-.37919 1.25003-.24724.37001-.59864.65837-1.00977.82867-.41114.1703-.86354.2149-1.29999.1281-.43646-.0868-.83737-.3011-1.15204-.61581-.31467-.31467-.52896-.71558-.61578-1.15204-.08681-.43645-.04226-.88885.12804-1.29999.1703-.41113.45869-.76253.8287-1.00977C7.11998 6.13196 7.55499 6 8 6c.59674 0 1.16903.23705 1.59099.65901.42191.42196.65901.99425.65901 1.59099Z"
        fill="currentColor"
      />
    </IconBase>
  );
};

export default memo(PhotoIcon);

import clsx from "clsx";
import { ReactNode, useCallback, useState } from "react";

import Button, { ButtonColor } from "@repo/ui/Button";
import Icon, { IconDirection, IconSize, IconType } from "@repo/ui/Icon";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import TabContent from "./TabContent";
import TabDetail from "./TabDetail";
import { Card } from "./types";

import * as s from "./CardTab.module.scss";

export type CardTabProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Информация о карточки. */
  card: Card;
  /** Мобильная версия. */
  isMobile: boolean;
  /** Состояние скрываемой части. */
  defaultOpened?: boolean;
  /** Возможность скрыть содержимое. */
  isCollapsable?: boolean;
  /** Обработчик клика по карточке. */
  onToggleClick?: (id: string) => void;
  /** Дочерние элементы или функция, возвращающая дочерние элементы. */
  children:
    | ReactNode
    | ((props: { isOpened: boolean; isCollapsable: boolean }) => ReactNode);
};

interface ICardTabComponent extends React.FC<CardTabProps> {
  /** Вложенный компонент TabContent. */
  Content: typeof TabContent;
  /** Вложенный компонент TabDetail. */
  Detail: typeof TabDetail;
}

const CardTab: ICardTabComponent = ({
  className,
  card,
  isMobile,
  defaultOpened,
  isCollapsable = true,
  onToggleClick,
  children,
}: CardTabProps) => {
  const [isOpened, setIsOpened] = useState<boolean>(Boolean(defaultOpened));

  const handleClick = useCallback(() => {
    setIsOpened((prevState) => !prevState);

    onToggleClick?.(card.id);
  }, [card.id, onToggleClick]);

  return (
    <div className={clsx(s["root"], className)}>
      <div className={s["root__wrapper"]}>
        <Icon className={s["root__icon"]} type={card.iconType} />

        <div className={s["root__content"]}>
          <div className={s["root__title"]}>
            <Typography
              typographyType={
                isMobile ? TypographyType.BODY_MD : TypographyType.H3
              }
              typographyWeight={
                isMobile ? TypographyWeight._500 : TypographyWeight._700
              }
            >
              {card.title}
            </Typography>

            {card.subtitle && (
              <Typography
                className={s["root__subtitle"]}
                typographyType={
                  isMobile ? TypographyType.CAPTION : TypographyType.BODY_LG
                }
                typographyWeight={TypographyWeight._500}
              >
                {card.subtitle}
              </Typography>
            )}
          </div>

          {!isMobile &&
            (typeof children === "function"
              ? children({ isOpened, isCollapsable })
              : children)}
        </div>

        {isCollapsable && (
          <Button.Text
            className={s["root__button"]}
            color={ButtonColor.DEFAULT}
            onClick={handleClick}
          >
            <Icon
              direction={isOpened ? IconDirection.DOWN : IconDirection.UP}
              size={IconSize.M}
              type={IconType.ARROW_DOWN}
            />
          </Button.Text>
        )}
      </div>

      {isMobile &&
        (typeof children === "function"
          ? children({ isOpened, isCollapsable })
          : children)}
    </div>
  );
};

CardTab.Content = TabContent;
CardTab.Detail = TabDetail;

export default CardTab;

import { action, computed, makeObservable, observable } from "mobx";

import { ILocalStore } from "../../interfaces/ILocalStore";

import type { Meta } from "./types";
import {
  getInitialMetaState,
  getLoadedErrorMeta,
  getLoadedSuccessMeta,
  getLoadingStartMeta,
  isInitialMetaState,
} from "./utils";

/**
 * Mobx стор для работы с meta
 */

class MetaStore implements ILocalStore {
  /** Состояния */
  meta: Meta;

  /** Перевод в состояние
   *
   * isLoading: true
   *
   * isError: false
   *
   * isLoaded: false
   */
  setLoadingStart: () => void;

  /** Перевод в состояние
   *
   * isLoading: false
   *
   * isError: false
   *
   * isLoaded: true
   */
  setLoadedSuccessMeta: () => void;

  /** Перевод в состояние
   *
   * isLoading: false
   *
   * isError: false
   *
   * isLoaded: false
   */
  setInitialMetaState: () => void;

  /**
   * @param initialMeta Начальное состояние
   */
  constructor(initialMeta = getInitialMetaState()) {
    this.meta = initialMeta;

    makeObservable(this, {
      meta: observable.ref,
      isInitialMetaState: computed,
      setMeta: action.bound,
    });

    this.setLoadingStart = this.setMeta.bind(this, getLoadingStartMeta());
    this.setLoadedSuccessMeta = this.setMeta.bind(this, getLoadedSuccessMeta());
    this.setInitialMetaState = this.setMeta.bind(this, getInitialMetaState());
  }

  /** Сейчас начальное состояние */
  get isInitialMetaState(): boolean {
    return isInitialMetaState(this.meta);
  }

  /**
   * Установка состояния
   *
   * @param meta Состояние которое нужно установить
   */
  setMeta(meta: Meta): void {
    this.meta = meta;
  }

  /** Перевод в состояние
   *
   * isLoading: true
   *
   * isError: true
   *
   * isLoaded: false
   *
   * @param message Сообщение
   */
  setLoadedErrorMeta(message?: string): void {
    this.setMeta(getLoadedErrorMeta(message));
  }

  destroy(): void {}
}

export default MetaStore;

import { configure } from "mobx";
import { enableLogging } from "mobx-logger";

export const initMobX = (): void => {
  const isDev = process.env.NODE_ENV === "development";
  const isTest = process.env.NODE_ENV === "test";

  configure({
    enforceActions: "always",
    computedRequiresReaction: !isTest,
    reactionRequiresObservable: !isTest,
    observableRequiresReaction: !isTest,
    useProxies: "ifavailable",
    disableErrorBoundaries: isDev,
  });

  if (isDev) {
    enableLogging({
      action: true,
      reaction: true,
      transaction: true,
      compute: true,
    });
  }
};

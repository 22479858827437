import { memo } from "react";

import IconBase, { type IconBaseProps } from "./IconBase";

const ArrowDownIcon: React.FC<IconBaseProps> = (props: IconBaseProps) => {
  return (
    <IconBase {...props} height="24" viewBox="0 0 24 24" width="24">
      <path
        d="M17.0002 9.1697c-.1874-.18625-.4408-.29079-.705-.29079-.2642 0-.5176.10454-.705.29079l-3.59 3.54-3.54001-3.54c-.18736-.18625-.44081-.29079-.705-.29079-.26418 0-.51763.10454-.705.29079-.09373.09296-.16812.20356-.21889.32542-.05077.12186-.07691.25257-.07691.38458 0 .132.02614.2627.07691.3846.05077.1218.12516.2324.21889.3254l4.24001 4.24c.093.0937.2036.1681.3254.2189.1219.0508.2526.0769.3846.0769s.2627-.0261.3846-.0769c.1218-.0508.2324-.1252.3254-.2189l4.29-4.24c.0937-.093.1681-.2036.2189-.3254.0508-.1219.0769-.2526.0769-.3846 0-.13201-.0261-.26272-.0769-.38458-.0508-.12186-.1252-.23246-.2189-.32542Z"
        fill="currentColor"
      />
    </IconBase>
  );
};

export default memo(ArrowDownIcon);

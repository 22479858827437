import type {
  ApiPath,
  FetchApiOptions,
  GetFetchApiData,
  GetFetchApiMethod,
  GetFetchApiPathData,
} from "../fetchApi";
import { combineMiddleware } from "../utils";

import {
  addEventListener,
  type AddEventListenerApiWithChecksType,
  type RemoveApiWithChecksEventListenerType,
  removeEventListener,
} from "./event";
import callFetchApi from "./middleware/callFetchApi";
import checkNotAuthorizedLayer from "./middleware/checkNotAuthorizedLayer";
import { ApiWithChecksResult } from "./types";

const middleware = combineMiddleware([checkNotAuthorizedLayer], callFetchApi);

interface IApiWithChecks {
  <P extends ApiPath, M extends GetFetchApiMethod<P>>(
    path: P,
    method: M,
    options: FetchApiOptions<GetFetchApiPathData<P, M>, GetFetchApiData<P, M>>,
  ): ApiWithChecksResult<P, M>;
  /** Добавление слушателя */
  addEventListener: AddEventListenerApiWithChecksType;
  /** Удаление слушателя */
  removeEventListener: RemoveApiWithChecksEventListenerType;
}

const apiWithChecks: IApiWithChecks = (path, method, options) =>
  middleware({ path, method, options });

apiWithChecks.addEventListener = addEventListener;
apiWithChecks.removeEventListener = removeEventListener;

export default apiWithChecks;

import clsx from "clsx";
import React, { memo, useMemo } from "react";

import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import useWidth from "@app/hooks/useWidth";

import { MoneyValueSize } from "./config";

import * as s from "./MoneyValue.module.scss";

type Props = {
  /** Сумма */
  value: number;
  /** Если true, значение будет размыто. */
  isVisible: boolean;
  /** Размер текста */
  size: MoneyValueSize;
};

const MoneyValue: React.FC<Props> = ({ value, isVisible, size }) => {
  const { isDesktop } = useWidth();

  const { sign, integerPart, fractionalPart } = useMemo(() => {
    const sign = value > 0 ? "+" : "-";
    const integerPart = Math.abs(Math.floor(value));
    const fractionalPart = Math.abs(value - integerPart)
      .toFixed(2)
      .slice(2);

    return {
      sign: value === 0 ? "" : sign,
      integerPart,
      fractionalPart,
    };
  }, [value]);

  const typographyType = useMemo(() => {
    if (size === MoneyValueSize.SMALL) {
      return isDesktop ? TypographyType.BODY_SM : TypographyType.CAPS_SM;
    }

    return isDesktop ? TypographyType.H4 : TypographyType.BODY_MD;
  }, [isDesktop, size]);

  return (
    <Typography
      className={clsx(s["root"], !isVisible && s["root_blur"])}
      typographyType={typographyType}
      typographyWeight={TypographyWeight._500}
    >
      {sign.length > 0 && <span>{sign}&nbsp;</span>}
      <span>{integerPart}</span>
      <span className={s["root__fractional"]}>.{fractionalPart}&nbsp;руб.</span>
    </Typography>
  );
};

export default memo(MoneyValue);

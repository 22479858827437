import { ChartLegendItemType } from "./types";

export enum ChartLegendItemEnum {
  /** Выплаты */
  INCOME = "income",
  /** Удержания */
  EXPENSE = "expense",
}

export const CHART_LEGEND_MAP: Record<
  ChartLegendItemEnum,
  ChartLegendItemType
> = {
  [ChartLegendItemEnum.INCOME]: {
    label: "Выплаты",
    color: "#8a60ff",
  },
  [ChartLegendItemEnum.EXPENSE]: {
    label: "Удержания",
    color: "#f72874",
  },
};

import { useNavigate } from "@tanstack/react-router";
import clsx from "clsx";
import { memo, useCallback } from "react";

import GlobalAuthStore from "@repo/data/store/single/GlobalAuthStore";
import Button, { ButtonColor } from "@repo/ui/Button";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import useWidth from "@app/hooks/useWidth";

import * as s from "./ControlButtons.module.scss";

export type ControlButtonsProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
};

const ControlButtons: React.FC<ControlButtonsProps> = ({
  className,
}: ControlButtonsProps) => {
  const { isPhone } = useWidth();

  const navigate = useNavigate();

  const globalAuthStore = GlobalAuthStore.getInstance();

  const handleLogoutClick = useCallback(async () => {
    await globalAuthStore.logout();

    navigate({
      to: "/login",
    });
  }, [globalAuthStore, navigate]);

  return (
    <div className={clsx(s["control-buttons"], className)}>
      <Button.Text
        className={s["control-buttons__button"]}
        color={ButtonColor.PLACEHOLDER}
      >
        <Typography
          Component="span"
          typographyType={
            isPhone ? TypographyType.CAPS_SM : TypographyType.CAPS_MD
          }
          typographyWeight={TypographyWeight._700}
        >
          Сбросить пароль
        </Typography>
      </Button.Text>

      <Button.Text className={s["control-buttons__button"]}>
        <Typography
          Component="span"
          typographyType={
            isPhone ? TypographyType.CAPS_SM : TypographyType.CAPS_MD
          }
          typographyWeight={TypographyWeight._700}
          onClick={handleLogoutClick}
        >
          Выйти
        </Typography>
      </Button.Text>
    </div>
  );
};

export default memo(ControlButtons);

import { memo } from "react";

import IconBase, { type IconBaseProps } from "./IconBase";

const LockKeyIcon: React.FC<IconBaseProps> = (props: IconBaseProps) => {
  return (
    <IconBase {...props} height="24" viewBox="0 0 24 24" width="24">
      <path
        d="M12 8c-.5304 0-1.0391.21071-1.4142.58579C10.2107 8.96086 10 9.46957 10 10c.0021.349.0955.6914.271.9932.1754.3017.4267.5523.729.7268V15c0 .2652.1054.5196.2929.7071S11.7348 16 12 16c.2652 0 .5196-.1054.7071-.2929S13 15.2652 13 15v-3.28c.3023-.1745.5536-.4251.7291-.7268.1754-.3018.2688-.6442.2709-.9932 0-.53043-.2107-1.03914-.5858-1.41421C13.0391 8.21071 12.5304 8 12 8Zm0-6c-1.9778 0-3.91121.58649-5.5557 1.6853-1.64449 1.09882-2.92621 2.6606-3.68309 4.48787-.75688 1.82723-.95491 3.83793-.56906 5.77773s1.33826 3.7216 2.73679 5.1202c1.39852 1.3985 3.18035 2.3509 5.12016 2.7368 1.9398.3858 3.9505.1878 5.7777-.5691 1.8273-.7569 3.3891-2.0386 4.4879-3.6831C21.4135 15.9112 22 13.9778 22 12c0-1.3132-.2587-2.61358-.7612-3.82683-.5025-1.21326-1.2391-2.31565-2.1677-3.24424-.9286-.92858-2.031-1.66518-3.2443-2.16773C14.6136 2.25866 13.3132 2 12 2Zm0 18c-1.5822 0-3.12896-.4692-4.44456-1.3482-1.31559-.8791-2.34097-2.1285-2.94647-3.5903-.6055-1.4618-.76393-3.0704-.45525-4.6222.30868-1.55187 1.07061-2.97733 2.18943-4.09615C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372c1.5518-.30868 3.1604-.15026 4.6222.45524 1.4618.60551 2.7112 1.63088 3.5903 2.94648C19.5308 8.87103 20 10.4177 20 12c0 2.1217-.8428 4.1566-2.3431 5.6569C16.1566 19.1571 14.1217 20 12 20Z"
        fill="currentColor"
      />
    </IconBase>
  );
};

export default memo(LockKeyIcon);

import { useCallback } from "react";

import { ModalsBaseKeys } from "@repo/data/store/single/GlobalModalsStore";
import GlobalStoriesStore, {
  StoryType,
} from "@repo/data/store/single/GlobalStoriesStore";

import { useModalsStoreOpenState } from "../useModalsStoreOpenState";

function useModalStories() {
  const globalStoriesStore = GlobalStoriesStore.getInstance();
  const modalStore = useModalsStoreOpenState(ModalsBaseKeys.STORY);

  const handleOpen = useCallback(
    (story: StoryType) => {
      globalStoriesStore.setActiveStory(story);

      setTimeout(() => {
        modalStore.open();
      }, 0);
    },
    [globalStoriesStore, modalStore],
  );

  const handleClose = useCallback(() => {
    modalStore.close();

    setTimeout(() => {
      globalStoriesStore.setActiveStory(null);
    }, 300);
  }, [globalStoriesStore, modalStore]);

  return {
    handleOpen,
    handleClose,
    modalStore,
    activeStory: globalStoriesStore.activeStory,
  };
}

export default useModalStories;

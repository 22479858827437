import clsx from "clsx";
import { memo, useCallback, useState } from "react";

import Button from "@repo/ui/Button";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";
import { useTextWithHideRef } from "@repo/ui/hooks/useTextWithHide";

import * as s from "./Description.module.scss";

type Props = {
  /** Текст описания. */
  text: string;
};

const WithHide: React.FC<Props> = ({ text }) => {
  const [hide, setHide] = useState(true);

  const handleClick = useCallback(() => {
    setHide((prev) => !prev);
  }, []);

  return (
    <>
      <span
        className={clsx(
          s["description__text"],
          hide && s["description__text_hide"],
        )}
      >
        {text}
      </span>
      <Button.Text
        className={s["description__show-more"]}
        onClick={handleClick}
      >
        <Typography
          Component="span"
          typographyType={TypographyType.BODY_SM}
          typographyWeight={TypographyWeight._500}
        >
          {hide ? "Показать больше" : "Скрыть"}
        </Typography>
      </Button.Text>
    </>
  );
};

const Description: React.FC<Props> = ({ text }) => {
  const [ref, withHide] = useTextWithHideRef<HTMLSpanElement>({ countLine: 2 });

  return (
    <Typography
      Component="p"
      className={s["description"]}
      typographyType={TypographyType.BODY_SM}
      typographyWeight={TypographyWeight._500}
    >
      {withHide ? (
        <WithHide text={text} />
      ) : (
        <span ref={ref} className={clsx(s["description__text"])}>
          {text}
        </span>
      )}
    </Typography>
  );
};

export default memo(Description);

export enum AvatarSize {
  /** height: 36px/60px */
  S = "s",
  /** height: 36px/64px */
  M = "m",
  /** height: 100% */
  UNSET = "unset",
}

export enum AvatarAcceptTypes {
  jpeg = "image/jpeg",
  png = "image/png",
}

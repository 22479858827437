import clsx from "clsx";
import { motion, useAnimation } from "framer-motion";
import { forwardRef, memo, useEffect, useRef } from "react";

import Button from "@repo/ui/Button";
import Counter, { CounterSize } from "@repo/ui/Counter";
import Icon, { IconSize, IconType } from "@repo/ui/Icon";

import { BELL_VARIANTS } from "./config";

import * as s from "./NotificationBell.module.scss";

export type NotificationBellProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Количество уведомлений. */
  count: number;
};

const NotificationBell = forwardRef<HTMLButtonElement, NotificationBellProps>(
  ({ className, count, ...props }: NotificationBellProps, ref) => {
    const isFirstRender = useRef<boolean>(true);

    const controls = useAnimation();

    useEffect(() => {
      if (isFirstRender.current) {
        isFirstRender.current = false;
        return;
      }

      controls.start("ring");
    }, [controls, count]);

    return (
      <Button.Default
        ref={ref}
        className={clsx(s["bell"], className)}
        {...props}
      >
        <motion.div
          animate={controls}
          className={s["bell__icon"]}
          variants={BELL_VARIANTS}
          whileTap="tap"
        >
          <Icon size={IconSize.M} type={IconType.BELL} />
        </motion.div>

        <Counter
          withBorder
          className={clsx(
            s["bell__counter"],
            count > 0 && s["bell__counter_visible"],
          )}
          count={count.toString()}
          size={CounterSize.M}
        />
      </Button.Default>
    );
  },
);

NotificationBell.displayName = "NotificationBell";

export default memo(NotificationBell);

import { useEffect } from "react";

import { ILocalStore } from "../store/interfaces/ILocalStore";

import useLocal from "./useLocal";
/**
 * Хук `useLocalStore` создает и сохраняет локальный стор,
 * а также автоматически уничтожает его при размонтировании компонента.
 *
 * @template T Локальный стор, который должен реализовывать интерфейс `ILocalStore`.
 * @param {() => T} creator Функция, создающая начальное состояние стора.
 * @returns {T} Возвращает текущее состояние стора.
 */
const useLocalStore = <T extends ILocalStore>(creator: () => T): T => {
  const store = useLocal(creator);

  useEffect(() => {
    return () => {
      store.destroy();
    };
  }, []);

  return store;
};

export default useLocalStore;

import clsx from "clsx";
import { type PropsWithChildren } from "react";

import * as s from "./Layout.module.scss";

export type LayoutProps = {
  /** Дополнительные CSS классы для компонента. */
  className?: string;
  /** HTML тег, который будет использоваться для контейнера. */
  tag?: "div" | "section";
};

const Layout: React.FC<PropsWithChildren<LayoutProps>> = ({
  className,
  tag: Tag = "div",
  children,
}: PropsWithChildren<LayoutProps>) => {
  return (
    <Tag className={s["layout"]}>
      <div className={clsx(s["layout__container"], className)}>{children}</div>
    </Tag>
  );
};

export default Layout;

import type { MaskitoOptions } from "@maskito/core";

export const PHONE_MASK_OPTIONS = {
  mask: [
    "+",
    "7",
    " ",
    "(",
    /\d/,
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
  ],
} satisfies MaskitoOptions;

import { memo, useCallback, useState } from "react";

import Icon, { IconSize, IconType } from "@repo/ui/Icon";

import InputBase, { type InputBaseProps } from "../InputBase";

export type InputPasswordProps = Omit<InputBaseProps, "afterSlot" | "type">;

const InputPassword: React.FC<InputPasswordProps> = (
  props: InputPasswordProps,
) => {
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);

  const handleIconClick = useCallback(() => {
    setIsShowPassword((prev) => !prev);
  }, []);

  return (
    <InputBase
      {...props}
      afterSlot={
        <Icon
          disabled={props.disabled}
          size={IconSize.M}
          type={isShowPassword ? IconType.EYE_OPEN : IconType.EYE_CLOSE}
          onClick={handleIconClick}
        />
      }
      type={isShowPassword ? "text" : "password"}
    />
  );
};

export default memo(InputPassword);

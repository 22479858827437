import { memo } from "react";

import IconBase, { type IconBaseProps } from "./IconBase";

const CloseMenuIcon: React.FC<IconBaseProps> = (props: IconBaseProps) => {
  return (
    <IconBase {...props} height="24" viewBox="0 0 24 24" width="24">
      <path
        d="M22.125 12C22.125 12.2984 22.0065 12.5845 21.7955 12.7955C21.5845 13.0065 21.2984 13.125 21 13.125H10.2188L15.0487 17.9541C15.2601 18.1654 15.3788 18.4521 15.3788 18.7509C15.3788 19.0498 15.2601 19.3365 15.0487 19.5478C14.8374 19.7592 14.5508 19.8779 14.2519 19.8779C13.953 19.8779 13.6663 19.7592 13.455 19.5478L6.705 12.7978C6.60012 12.6933 6.5169 12.5691 6.46012 12.4324C6.40334 12.2956 6.37411 12.149 6.37411 12.0009C6.37411 11.8529 6.40334 11.7063 6.46012 11.5695C6.5169 11.4328 6.60012 11.3086 6.705 11.2041L13.455 4.45406C13.5596 4.34942 13.6839 4.2664 13.8206 4.20977C13.9573 4.15314 14.1039 4.12399 14.2519 4.12399C14.3999 4.12399 14.5464 4.15314 14.6831 4.20977C14.8199 4.2664 14.9441 4.34942 15.0487 4.45406C15.1534 4.55871 15.2364 4.68294 15.293 4.81967C15.3497 4.9564 15.3788 5.10294 15.3788 5.25094C15.3788 5.39893 15.3497 5.54548 15.293 5.6822C15.2364 5.81893 15.1534 5.94317 15.0487 6.04781L10.2188 10.875H21C21.2984 10.875 21.5845 10.9935 21.7955 11.2045C22.0065 11.4155 22.125 11.7016 22.125 12ZM3.75 2.625C3.45163 2.625 3.16548 2.74353 2.9545 2.9545C2.74353 3.16548 2.625 3.45163 2.625 3.75V20.25C2.625 20.5484 2.74353 20.8345 2.9545 21.0455C3.16548 21.2565 3.45163 21.375 3.75 21.375C4.04837 21.375 4.33452 21.2565 4.5455 21.0455C4.75647 20.8345 4.875 20.5484 4.875 20.25V3.75C4.875 3.45163 4.75647 3.16548 4.5455 2.9545C4.33452 2.74353 4.04837 2.625 3.75 2.625Z"
        fill="currentColor"
      />
    </IconBase>
  );
};

export default memo(CloseMenuIcon);

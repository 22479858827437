import React, { memo } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

import useWidth from "@app/hooks/useWidth";

import { CHART_LEGEND_MAP } from "../ChartLegend/ChartLegendItem";

import { ChartData } from "./types";

type Props = {
  /** Дополнительные CSS классы для компонента. */
  className?: string;
  /** Данные для графика */
  data: ChartData[];
};

const Chart: React.FC<Props> = ({ className, data }) => {
  const { isDesktop } = useWidth();

  const pieWidth = 13;
  const chartHeight = isDesktop ? 182 : 135;
  const chartWidth = isDesktop ? 362 : 268;

  return (
    <div className={className}>
      <ResponsiveContainer
        height={chartHeight + pieWidth}
        width={chartWidth + pieWidth}
      >
        <PieChart>
          <Pie
            blendStroke
            cornerRadius={pieWidth}
            cx={chartWidth / 2}
            cy={chartHeight}
            data={data}
            dataKey="value"
            endAngle={0}
            innerRadius={chartHeight - pieWidth}
            outerRadius={chartHeight}
            startAngle={180}
          >
            {data.map((entry) => (
              <Cell
                key={entry.name}
                fill={CHART_LEGEND_MAP[entry.name].color}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default memo(Chart);

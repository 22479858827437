import { makeObservable, observable } from "mobx";

import { ModalsStoreKeys } from "./types";

class ModalsStore {
  private readonly _openedModals = new Set<ModalsStoreKeys>([]);

  constructor() {
    makeObservable<this, "_openedModals">(this, {
      _openedModals: observable.shallow,
    });
  }

  openModal(key: ModalsStoreKeys): void {
    this._openedModals.add(key);
  }

  closeModal(key: ModalsStoreKeys): void {
    this._openedModals.delete(key);
  }

  getIsOpenedModal(key: ModalsStoreKeys): boolean {
    return this._openedModals.has(key);
  }
}

export default ModalsStore;

// extracted by mini-css-extract-plugin
var _1 = "icon__BFvUS";
var _2 = "icon_button__nP9nx";
var _3 = "icon_direction-down__O9xsS";
var _4 = "icon_direction-left__z6Y5B";
var _5 = "icon_direction-right__rK1Aj";
var _6 = "icon_direction-up__cn0Hx";
var _7 = "icon_size-l__cmczJ";
var _8 = "icon_size-m__asrMV";
var _9 = "icon_size-s__BUyLE";
var _a = "icon_size-xl__zcER9";
var _b = "icon_size-xs__Y0J6v";
export { _1 as "icon", _2 as "icon_button", _3 as "icon_direction-down", _4 as "icon_direction-left", _5 as "icon_direction-right", _6 as "icon_direction-up", _7 as "icon_size-l", _8 as "icon_size-m", _9 as "icon_size-s", _a as "icon_size-xl", _b as "icon_size-xs" }

import { RouterProvider } from "@tanstack/react-router";

import useTheme from "@app/hooks/useTheme";

import useAuthStage from "./hooks/useAuthStage";
import useSubscribeUIWidth from "./hooks/useSubscribeUIWidth";
import routerInstance from "./router";

import "@repo/ui/styles/global.scss";

const App: React.FC = () => {
  useTheme();
  useSubscribeUIWidth();
  const { isAuth } = useAuthStage();

  return <RouterProvider context={{ isAuth }} router={routerInstance} />;
};

export default App;

import {
  FloatingFocusManager,
  useMergeRefs,
  useTransitionStyles,
  type UseTransitionStylesProps,
} from "@floating-ui/react";
import clsx from "clsx";
import { forwardRef, memo } from "react";

import { TRANSITION_VARIANT } from "@repo/ui/Popover";

import { usePopoverContext } from "../../context";
import PopoverPortal from "../PopoverPortal";

import * as s from "./PopoverContent.module.scss";

type PopoverContentProps = {
  /** Анимация появления поповера. */
  transition?: UseTransitionStylesProps;
} & React.HTMLProps<HTMLDivElement>;

const PopoverContent = forwardRef<HTMLDivElement, PopoverContentProps>(
  ({ className, transition, children, ...props }, propRef) => {
    const data = usePopoverContext();

    const ref = useMergeRefs([data.refs.setFloating, propRef]);

    const floatingStyles = data.withOverlay ? {} : data.floatingStyles;

    const { isMounted, styles } = useTransitionStyles(data.context, {
      ...TRANSITION_VARIANT.DEFAULT,
      ...transition,
    });

    if (!isMounted) {
      return null;
    }

    return (
      <PopoverPortal>
        <FloatingFocusManager context={data.context} modal={data.modal}>
          <div
            ref={ref}
            className={clsx(s["content"], className)}
            style={{ ...floatingStyles }}
            {...data.getFloatingProps(props)}
          >
            {isMounted && <div style={{ ...styles }}>{children}</div>}
          </div>
        </FloatingFocusManager>
      </PopoverPortal>
    );
  },
);

PopoverContent.displayName = "PopoverContent";

export default memo(PopoverContent);

import clsx from "clsx";
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useId,
  useRef,
  useState,
} from "react";

import * as s from "./InputBase.module.scss";

export type InputBaseProps = {
  /** Дополнительные CSS классы для компонента. */
  className?: string;
  /** Лейбл для поля ввода. */
  label?: string;
  /** Слот для отображения контента после поля ввода. */
  afterSlot?: React.ReactNode;
  /** Если true, поле ввода будет в состоянии ошибки. */
  error?: boolean;
  /** Если true, поле ввода будет заблокировано. */
  disabled?: boolean;
  /** Значение поля ввода. */
  value: string;
  /** Обработчик изменения значения поля ввода. */
  onChange?: (
    value: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
} & Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "disabled" | "value" | "onChange"
>;

const InputBase = forwardRef<HTMLInputElement, InputBaseProps>(
  (
    {
      className,
      label,
      afterSlot,
      error,
      disabled,
      value,
      onChange,
      ...props
    }: InputBaseProps,
    refForward,
  ) => {
    const id = useId();

    const ref = useRef<HTMLInputElement>(null);

    const [isFocused, setIsFocused] = useState<boolean>(false);

    useEffect(() => {
      if (!refForward) {
        return;
      }

      if (typeof refForward === "function") {
        refForward(ref.current);
        return;
      }

      refForward.current = ref.current;
    }, [refForward]);

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (disabled || !onChange) {
          return;
        }

        onChange(e.target.value, e);
      },
      [disabled, onChange],
    );

    const handleBlur = useCallback(() => {
      setIsFocused(false);
    }, []);

    const handleFocus = useCallback(() => {
      setIsFocused(true);
    }, []);

    const handleClick = useCallback<
      React.MouseEventHandler<HTMLDivElement>
    >(() => {
      if (!ref.current || isFocused || disabled) {
        return;
      }

      ref.current.focus();
      setIsFocused(true);
    }, [isFocused, disabled]);

    const isFullView = Boolean(value) || isFocused;

    return (
      <div
        className={clsx(
          s["input"],
          isFullView && s["input_full-view"],
          afterSlot && s["input_with-slot"],
          error && s["input_error"],
          disabled && s["input_disabled"],
          className,
        )}
        onBlur={handleBlur}
        onClick={handleClick}
        onFocus={handleFocus}
      >
        {Boolean(label) && (
          <label className={s["input__label"]} htmlFor={id}>
            {label}
          </label>
        )}

        <input
          ref={ref}
          className={s["input__field"]}
          disabled={disabled}
          id={id}
          value={value}
          onChange={handleChange}
          {...props}
        />

        {Boolean(afterSlot) && (
          <div
            className={s["input__slot"]}
            onClick={(e) => e.stopPropagation()}
          >
            {afterSlot}
          </div>
        )}
      </div>
    );
  },
);

InputBase.displayName = "InputBase";

export default memo(InputBase);

import { memo, PropsWithChildren } from "react";

import { PopoverContext } from "./context";
import { usePopover, type UsePopoverOptions } from "./hooks";

export type PopoverProps = PropsWithChildren<UsePopoverOptions>;

const Popover: React.FC<PopoverProps> = ({
  children,
  ...props
}: PopoverProps) => {
  const popover = usePopover({ ...props });

  return (
    <PopoverContext.Provider value={popover}>
      {children}
    </PopoverContext.Provider>
  );
};

export default memo(Popover);

// extracted by mini-css-extract-plugin
var _1 = "root__CRyo8";
var _2 = "root__icon__ZlFBf";
var _3 = "root__icon_large__U02Js";
var _4 = "root__icon_luminosity__DTyDR";
var _5 = "root__icon_small__VOvK6";
var _6 = "root__left__HHQBU";
var _7 = "root__numbers__aBqJr";
var _8 = "root__right__LmPTY";
var _9 = "root__title__KOsso";
var _a = "root_color__RYxJT";
var _b = "root_color-main__dbwvc";
var _c = "root_color-secondary__PJgld";
export { _1 as "root", _2 as "root__icon", _3 as "root__icon_large", _4 as "root__icon_luminosity", _5 as "root__icon_small", _6 as "root__left", _7 as "root__numbers", _8 as "root__right", _9 as "root__title", _a as "root_color", _b as "root_color-main", _c as "root_color-secondary" }

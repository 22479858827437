import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

import Modal from "@repo/ui/Modal";

import useModalStories from "@app/hooks/story/useModalStories";
import useWidth from "@app/hooks/useWidth";

import { WIDGET_CAROUSEL_HISTORY_CONFIG } from "../widgets/WidgetCarouselStories/config";

import ModalStoriesNavigation from "./components/ModalStoriesNavigation";
import StoryItem from "./components/StoryItem";

import * as s from "./ModalStories.module.scss";

const ModalStories: React.FC = () => {
  const { isPhone } = useWidth();

  const modalStories = useModalStories();

  const sliderRef = useRef<SwiperRef>(null);

  const [isBeginning, setIsBeginning] = useState(false);
  const [isEnd, setIsEnd] = useState(false);

  const handlePrev = useCallback(() => {
    sliderRef.current?.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    sliderRef.current?.swiper.slideNext();
  }, []);

  const handleClose = useCallback(() => {
    modalStories.handleClose();
  }, [modalStories]);

  const initialSlideIndex = useMemo(() => {
    return WIDGET_CAROUSEL_HISTORY_CONFIG.findIndex(
      (item) => item.id === modalStories.activeStory?.id,
    );
  }, [modalStories.activeStory?.id]);

  return (
    <Modal
      className={s["root"]}
      isMobile={isPhone}
      isOpened={modalStories.modalStore.opened}
      onClose={handleClose}
    >
      <Swiper
        ref={sliderRef}
        className={clsx(
          s["root__swiper"],
          modalStories.modalStore.opened && s["root__swiper_opened"],
        )}
        initialSlide={initialSlideIndex}
        modules={[Navigation]}
        navigation={{
          enabled: true,
          prevEl: null,
          nextEl: null,
        }}
        slidesPerView={1}
        onSnapIndexChange={(e) => {
          setIsBeginning(e.isBeginning);
          setIsEnd(e.isEnd);
        }}
      >
        {WIDGET_CAROUSEL_HISTORY_CONFIG.map((item) => (
          <SwiperSlide key={item.id} className={s["root__slide"]}>
            <StoryItem item={item} />
          </SwiperSlide>
        ))}
        <ModalStoriesNavigation
          handleNext={handleNext}
          handlePrev={handlePrev}
          isNextDisabled={isEnd}
          isPrevDisabled={isBeginning}
        />
      </Swiper>
    </Modal>
  );
};

export default observer(ModalStories);

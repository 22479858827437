import clsx from "clsx";
import {
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import * as s from "./ScrollLayout.module.scss";

const ScrollLayout: React.FC<PropsWithChildren<{ className?: string }>> = ({
  className,
  children,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [isEnableShadow, setIsEnableShadow] = useState(false);
  const [{ availableTop, availableBottom }, setState] = useState(() => ({
    availableTop: false,
    availableBottom: false,
  }));

  const handleScroll = useCallback(() => {
    if (!ref.current) {
      return;
    }

    const target = ref.current;

    const availableTop = target.scrollTop > 25;
    const availableBottom =
      target.scrollHeight - target.scrollTop - target.clientHeight > 25;

    setState({ availableTop, availableBottom });
  }, []);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    handleScroll();

    const handler = () => {
      if (!ref.current) {
        return;
      }

      setIsEnableShadow(ref.current.scrollHeight > ref.current.clientHeight);
    };

    const observer = new ResizeObserver(handler);
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div
      className={clsx(
        s["scroll"],
        isEnableShadow && s["scroll_enable-shadow"],
        availableTop && s["scroll_available-top"],
        availableBottom && s["scroll_available-bottom"],
      )}
    >
      <div
        ref={ref}
        className={clsx(s["scroll__inner"], className)}
        onScroll={handleScroll}
      >
        {children}
      </div>
    </div>
  );
};

export default memo(ScrollLayout);

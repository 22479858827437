import { useEffect, useRef } from "react";

import GlobalUIStore from "@repo/data/store/single/GlobalUIStore";

function useSubscribeUIWidth() {
  const unmounted = useRef<boolean>(false);
  const globalUIStore = GlobalUIStore.getInstance();

  useEffect(() => {
    globalUIStore.setWidth(window.innerWidth);

    const handleResize = () => {
      if (unmounted.current) {
        return;
      }

      globalUIStore.setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      unmounted.current = true;
      window.removeEventListener("resize", handleResize);
    };
  }, []);
}

export default useSubscribeUIWidth;

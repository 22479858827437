import { LinkProps, Link as RouterLink } from "@tanstack/react-router";
import clsx from "clsx";
import React from "react";

import Icon, { IconType } from "../Icon";
import Typography, { TypographyType, TypographyWeight } from "../Typography";

import { LinkColor } from "./config";

import * as s from "./Link.module.scss";

type Props = LinkProps & {
  /** Дополнительные CSS классы для компонента. */
  className?: string;
  /** Цвет ссылки */
  color: LinkColor;
  /** Текст ссылки */
  text: string;
  /** Мобильная версия */
  isMobile: boolean;
};

const Link: React.FC<Props> = ({
  text,
  className,
  color,
  isMobile,
  ...props
}) => {
  return (
    <RouterLink
      {...props}
      className={clsx(className, s["root"], s[`root_color-${color}`])}
    >
      <Typography
        className={s["root__text"]}
        typographyType={
          isMobile ? TypographyType.CAPTION_SM : TypographyType.BODY_SM
        }
        typographyWeight={TypographyWeight._500}
      >
        {text} <Icon className={s["root__icon"]} type={IconType.ARROW_RIGHT} />
      </Typography>
    </RouterLink>
  );
};

export default Link;

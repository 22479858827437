import { action, makeObservable, observable } from "mobx";

import { StoryType } from "./types";

type PrivateFields = "_activeStory";

class StoriesStore {
  private _activeStory: StoryType | null = null;

  get activeStory() {
    return this._activeStory;
  }

  constructor() {
    makeObservable<this, PrivateFields>(this, {
      _activeStory: observable.ref,

      setActiveStory: action.bound,
    });
  }

  setActiveStory(value: StoryType | null): void {
    this._activeStory = value;
  }
}

export default StoriesStore;

import Icon, { IconSize, IconType } from "@repo/ui/Icon";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import * as s from "./AppDownload.module.scss";

type Props = {
  /** Ссылка на скачивание приложения */
  url: string;
};

const AppDownload: React.FC<Props> = ({ url }) => {
  return (
    <Typography
      Component="a"
      className={s["app-download"]}
      href={url}
      rel="noopener noreferrer"
      target="_blank"
      typographyType={TypographyType.BODY_MD}
      typographyWeight={TypographyWeight._500}
    >
      <Icon size={IconSize.XL} type={IconType.PHONE_3D} />
      <span>
        Входите быстрее через <br />
        <span className={s["app-download__text-app"]}>
          мобильное приложение
        </span>
      </span>
    </Typography>
  );
};

export default AppDownload;

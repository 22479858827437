import { createRouter } from "@tanstack/react-router";

import { routeTree } from "./routeTree.gen";

const routerInstance = createRouter({
  routeTree,
  defaultPreload: "intent",
  defaultPendingMs: 0,
  context: {
    isAuth: false,
  },
});

declare module "@tanstack/react-router" {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Register {
    router: typeof routerInstance;
  }
}

export default routerInstance;

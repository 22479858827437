import React, { memo } from "react";

import ChartLegendItem from "./ChartLegendItem";
import { ChartLegendItemEnum } from "./ChartLegendItem";

import * as s from "./ChartLegend.module.scss";

type Props = {
  isVisible: boolean;
};

const ChartLegend: React.FC<Props> = ({ isVisible }) => {
  return (
    <div className={s["root"]}>
      <ChartLegendItem
        id={ChartLegendItemEnum.INCOME}
        isVisible={isVisible}
        value={32032}
      />
      <ChartLegendItem
        id={ChartLegendItemEnum.EXPENSE}
        isVisible={isVisible}
        value={-5735.77}
      />
    </div>
  );
};

export default memo(ChartLegend);

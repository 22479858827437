import React, { useState } from "react";

import InputSearch from "./InputSearch";
import TagsList from "./TagsList";

import * as s from "./WidgetSearch.module.scss";

const WidgetSearch: React.FC = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  // eslint-disable-next-line no-console
  const onSearch = () => console.log("onSearch", searchValue);

  return (
    <div className={s["root"]}>
      <div className={s["root__search"]}>
        <InputSearch
          value={searchValue}
          onChange={setSearchValue}
          onSearch={onSearch}
        />
        <TagsList
          list={[
            "График отпусков",
            "ДМС",
            "Кадровый резерв",
            "Обучающие программы",
            "Справочник сотрудников",
          ]}
          setValue={setSearchValue}
        />
      </div>
    </div>
  );
};

export default WidgetSearch;

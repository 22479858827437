import clsx from "clsx";
import { lazy, memo, Suspense } from "react";

import * as s from "./QRCodeBlock.module.scss";

export type QRCodeBlockProps = {
  /** Дополнительные CSS классы для компонента. */
  className?: string;
  /** Значение, которое будет закодировано в QR-коде. */
  value: string;
};

const QRCode = lazy(() => import("react-qr-code"));

const QRCodeBlock: React.FC<QRCodeBlockProps> = ({
  className,
  value,
}: QRCodeBlockProps) => {
  const Loader = <div className={s["code__loader"]} />;

  return (
    <div className={clsx(s["code"], className)}>
      <Suspense fallback={Loader}>
        <QRCode className={s["code__block"]} value={value} />
      </Suspense>
    </div>
  );
};

export default memo(QRCodeBlock);

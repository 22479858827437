import { makeObservable, observable } from "mobx";

import { SettingsType } from "./types";

type PrivateFields = "_data";

class SettingsStore {
  private _data: SettingsType;

  get data(): SettingsType {
    return this._data;
  }

  constructor(data: SettingsType) {
    this._data = data;

    makeObservable<this, PrivateFields>(this, {
      _data: observable.ref,
    });
  }
}

export default SettingsStore;

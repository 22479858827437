import avatarOne from "./employ-1.png";
import avatarTwo from "./employ-2.png";

export const EMPLOY_DATA = [
  {
    id: "1",
    name: "Андреев Сергей Геннадиевич",
    position: "Руководитель отдела закупок",
    details: [
      { id: "1", title: "Телефон", text: "+7 964 999-99-98" },
      { id: "2", title: "Электронная почта", text: "andreev@hello-hr.ru" },
    ],
    avatar: avatarOne,
  },
  {
    id: "2",
    name: "Митрофанова Ольга Павловна",
    position: "Менеджер по продажам",
    details: [
      { id: "1", title: "Телефон", text: "+7 964 999-99-98" },
      { id: "2", title: "Электронная почта", text: "mitrofanova@hello-hr.ru" },
    ],
    avatar: avatarTwo,
  },
];

export const ORGANIZATION_DATA = [
  {
    id: "1",
    title: "Стаж",
    text: "1 год и 3 месяца",
  },
  {
    id: "2",
    title: "Юридическое лицо",
    text: "ООО «Любимая компания»",
  },
  {
    id: "3",
    title: "Дата трудоустройства",
    text: "15.08.22",
  },
  {
    id: "4",
    title: "Оргструктура",
    text: "Любимая компания/филиал Волга/Отдел закупок",
  },
  {
    id: "5",
    title: "Должность",
    text: "Менеджер, занятость 100%",
  },
  {
    id: "6",
    title: "Место работы",
    text: "Офис «Океан», г. Самара, ул. Спортивная, д.13/2",
  },
  {
    id: "7",
    title: "Удаленная работа",
    text: "Нет",
  },
  {
    id: "8",
    title: "Форма работы",
    text: "Очная, график 5/2",
  },
];

export const EDO_DATA = [
  {
    id: "1",
    title: "Данные",
    text: "Подпись УНЭП",
  },
  {
    id: "2",
    title: "Период действия сертификата",
    text: "08.07.2024 — 08.07.2025",
  },
];

export const COIN_DATA = [
  {
    id: "1",
    title: "Квартальная премия",
    text: "15%",
  },
  {
    id: "2",
    title: "Ежегодная премия",
    text: "5%",
  },
];

export const CHILD_DATA = [
  {
    id: "1",
    title: "ФИО и Дата рождения",
    text: "Вяткина Мария Геннадьевич, 19.08.2017",
  },
  {
    id: "2",
    title: "ФИО и Дата рождения",
    text: "Вяткин Александр Геннадьевич, 19.08.2012",
  },
];

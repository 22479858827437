import { memo, useId } from "react";

import IconBase, { type IconBaseProps } from "./IconBase";

const Menu3dIcon: React.FC<IconBaseProps> = (props: IconBaseProps) => {
  const imgId = useId();
  const patternId = useId();

  return (
    <IconBase {...props} height="32" viewBox="0 0 32 32" width="32">
      <rect fill={`url(#${patternId})`} height="32" width="32" />
      <defs>
        <pattern
          height="1"
          id={patternId}
          patternContentUnits="objectBoundingBox"
          width="1"
        >
          <use transform="scale(0.00195312)" xlinkHref={`#${imgId}`} />
        </pattern>
        <image
          height="512"
          id={imgId}
          width="512"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJ5SURBVHgB7VS/a1RBEJ737mKC9rGwiDZiY2FhYZfOfyGdBKwECzvLiCCaNOJfoJ2VCArp0gpiEUQxZWyEI4fkeLncvd2ZHb+Z3Xs5kzpdBubN/vx295v5HtGFFavs8+ebXh4c0PK0DC7BrV1HqjwGqgJT1aKThJRaxAVE9KdwYVLfOyJaf1ntd8BfPuvbOKEHKflGmkVsyB6JYiDieLYfcUgICZEpwCfTo2cvPlzd6PtBQ1rp1QBTODZqKgCSQQyACxBzaYcM7sCtADzAIw2HzYphOnDT0E4ttOq3FSVxYHVQYdwmKkASwNQPZMwxxtjGomCe4YHaNlCi9mdHhdnzh5Mbxl22ltpCeL+3qEZk7k6dU+twGjn/7TRvmmKiV1/S97t39uk8zW/8dVvvHo/pkT1djUdQoZI59oTFuUQaXTHHaDGUZMYEGoTqJd188nrxl3M8+E2bKJ7VriqK81xVzBLoHMdZZYB7BsdInHnASc1RY5DrDowNh3V9UmYWZe6A0weerFN4IkUZJVVE9NVmS1UcDHirknqUUo0NWCh2EyzmGk3BDdkqQRnPFZZKsEZQFYFDJVBOxDOkVIb20hadp3ny8Ijq+w7dxOuWrOgJ/IWyoIaUQWM1Pwb92oMtz26WRJu8skxyf6360QFvv9M3fEyPpXDnFZGyCmfVwPF/ief2TCCWQFMf6ODx081P1145x+O/dNtkbLTrfOJOyzrmMa8WU56r0viPLueIq4+bya0uec0h7y70+6sz8K4yuEg4agHSPCbltszuBmhu/wvq6V5HhdnG2uQ6S5v7nYQdVPNY/ljghHWQctsNQ84h+a/z4969fbqwefsH4/RPOpXz4QsAAAAASUVORK5CYII="
        />
      </defs>
    </IconBase>
  );
};

export default memo(Menu3dIcon);

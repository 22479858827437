import { useObserver } from "mobx-react-lite";
import { useCallback } from "react";

import GlobalModalsStore, {
  type ModalsStoreKeys,
} from "@repo/data/store/single/GlobalModalsStore";
import type { IOpenState } from "@repo/data/types";

import useBodyScrollLock from "./useBodyScrollLock";

/**
 * Хук состояния модалки
 *
 * @param key Ключ модалки
 */
export const useModalsStoreOpenState = (
  key: ModalsStoreKeys,
): Omit<IOpenState, "toggle"> => {
  const modalStore = GlobalModalsStore.getInstance();

  const { lock, unlock } = useBodyScrollLock();

  const handleOpen = useCallback(() => {
    lock();

    modalStore.openModal(key);
  }, [lock, modalStore, key]);

  const handleClose = useCallback(() => {
    unlock();

    modalStore.closeModal(key);
  }, [unlock, modalStore, key]);

  return useObserver(() => ({
    opened: modalStore.getIsOpenedModal(key),
    open: handleOpen,
    close: handleClose,
  }));
};

import clsx from "clsx";
import { memo, useCallback, useState } from "react";
import Cropper, { Area, CropperProps, Point } from "react-easy-crop";

import Loader, { LoaderSize } from "@repo/ui/Loader";

import * as s from "./ImageCropper.module.scss";

export type ImageCropperProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Изображение в base64. */
  imageSrc: string;
  /** Соотношение сторон. */
  aspectRatio?: number;
  /** Тип отображения изображения. */
  objectFit?: CropperProps["objectFit"];
  /** Флаг загрузки изображения. */
  isLoading?: boolean;
  /** Выбранная область изображения. */
  onCropComplete: (croppedAreaPixels: Area) => void;
};

const ImageCropper: React.FC<ImageCropperProps> = ({
  className,
  imageSrc,
  aspectRatio = 1,
  objectFit = "cover",
  isLoading,
  onCropComplete,
}: ImageCropperProps) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);

  const handleCropComplete = useCallback(
    async (_: Area, croppedAreaPixels: Area) => {
      onCropComplete(croppedAreaPixels);
    },
    [onCropComplete],
  );

  return (
    <div className={clsx(s["cropper"], className)}>
      {isLoading ? (
        <Loader isAbsolute size={LoaderSize.M} />
      ) : (
        <Cropper
          aspect={aspectRatio}
          crop={crop}
          cropShape="round"
          image={imageSrc}
          objectFit={objectFit}
          showGrid={false}
          zoom={zoom}
          onCropChange={setCrop}
          onCropComplete={handleCropComplete}
          onZoomChange={setZoom}
        />
      )}
    </div>
  );
};

export default memo(ImageCropper);

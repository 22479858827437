import { Outlet, createRootRouteWithContext } from "@tanstack/react-router";
import { lazy } from "react";

import { apiWithChecks } from "@repo/api/apiWithChecks";
import { ApiPath } from "@repo/api/fetchApi";
import GlobalSettingsStore, {
  normalizeSettings,
} from "@repo/data/store/single/GlobalSettingsStore";
import Loader, { LoaderSize } from "@repo/ui/Loader";

const TanStackRouterDevtools =
  process.env.NODE_ENV === "production"
    ? () => null
    : lazy(() =>
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools,
        })),
      );

const { CLIENT_ID } = process.env;

const promiseLoadClientItem = (async () => {
  if (CLIENT_ID === undefined) {
    return;
  }

  const { data, isError } = await apiWithChecks(ApiPath.CLIENT_ITEM, "GET", {
    data: {
      id: CLIENT_ID,
    },
  });

  if (data && !isError) {
    GlobalSettingsStore.getInstance(normalizeSettings(data));
  }
})();

type MyRouterContext = {
  isAuth: boolean;
};

export const Route = createRootRouteWithContext<MyRouterContext>()({
  beforeLoad: async () => {
    await promiseLoadClientItem;
  },
  component: () => (
    <>
      <Outlet />
      <TanStackRouterDevtools initialIsOpen={false} position="bottom-right" />
    </>
  ),
  pendingComponent: () => <Loader isAbsolute size={LoaderSize.L} />,
});

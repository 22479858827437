import { Variants } from "framer-motion";

export const SUN_VARIANTS: Variants = {
  default: {
    x: 0,
    y: 0,
    scale: 1,
    opacity: 1,
  },
  initial: {
    x: 20,
    y: -20,
    scale: 0.4,
    opacity: 0,
  },
  exit: {
    x: -30,
    y: 20,
    scale: 0.4,
    opacity: 0,
  },
} as const;

export const MOON_VARIANTS: Variants = {
  default: {
    x: 0,
    y: 0,
    scale: 1,
    opacity: 1,
  },
  initial: {
    x: -20,
    y: 20,
    scale: 0.4,
    opacity: 0,
  },
  exit: {
    x: 30,
    y: -20,
    scale: 0.4,
    opacity: 0,
  },
} as const;

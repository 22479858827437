// extracted by mini-css-extract-plugin
var _1 = "root__HLt1C";
var _2 = "root__button__hX9X1";
var _3 = "root__content__BlSVh";
var _4 = "root__detail__VyS7C";
var _5 = "root__detail-title__Yg4q4";
var _6 = "root__details__tAYOm";
var _7 = "root__employ-name__e22Ym";
var _8 = "root__employ-position__cP0ea";
var _9 = "root__employ-text__WUElt";
var _a = "root__header__PTDer";
export { _1 as "root", _2 as "root__button", _3 as "root__content", _4 as "root__detail", _5 as "root__detail-title", _6 as "root__details", _7 as "root__employ-name", _8 as "root__employ-position", _9 as "root__employ-text", _a as "root__header" }

import clsx from "clsx";
import { memo } from "react";

import Button, { type ButtonDefaultProps } from "@repo/ui/Button";
import Icon, { IconSize, IconType } from "@repo/ui/Icon";

import * as s from "./ModalClose.module.scss";

const ModalClose: React.FC<ButtonDefaultProps> = ({
  className,
  ...props
}: ButtonDefaultProps) => {
  return (
    <Button.Default className={clsx(s["modal-close"], className)} {...props}>
      <Icon size={IconSize.M} type={IconType.CLOSE} />
    </Button.Default>
  );
};

export default memo(ModalClose);

import { Outlet, createFileRoute, redirect } from "@tanstack/react-router";

import { apiWithChecks } from "@repo/api/apiWithChecks";
import { ApiPath } from "@repo/api/fetchApi";
import GlobalAuthStore, {
  AuthStage,
} from "@repo/data/store/single/GlobalAuthStore";
import GlobalUserStore, {
  normalizeUser,
} from "@repo/data/store/single/GlobalUserStore";
import Loader, { LoaderSize } from "@repo/ui/Loader";

import Header from "@app/pages/auth/Header";
import Layout from "@app/pages/auth/Layout";
import MenuMobile from "@app/pages/auth/MenuMobile";
import Sidebar from "@app/pages/auth/Sidebar";

const promiseLoadUserCurrent = (async () => {
  const { data, isError } = await apiWithChecks(
    ApiPath.USER_CURRENT,
    "GET",
    {},
  );

  if (data && !isError) {
    GlobalUserStore.getInstance(normalizeUser(data));
    GlobalAuthStore.getInstance().login();
  }
})();

export const Route = createFileRoute("/_auth")({
  beforeLoad: async ({ location }) => {
    await promiseLoadUserCurrent;

    if (GlobalAuthStore.getInstance().stage !== AuthStage.AUTHENTICATED) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
  },
  component: () => (
    <Layout
      headerContent={<Header />}
      menuMobile={<MenuMobile />}
      sidebarContent={<Sidebar />}
    >
      <Outlet />
    </Layout>
  ),

  pendingComponent: () => <Loader isAbsolute size={LoaderSize.L} />,
});

import clsx from "clsx";
import { getDate, getMonth } from "date-fns";
import React, { memo, useMemo } from "react";

import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import useWidth from "@app/hooks/useWidth";

import CalendarCell from "../CalendarCell/CalendarCell";

import * as s from "./CalendarCellDay.module.scss";

type Props = React.PropsWithChildren & {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Дата */
  date: Date;
  /** Активный месяц*/
  activeMonth: number;
  /** Активный день */
  activeDay: number;
  /** Есть смена */
  isShift: boolean;
};

const CalendarCellDay: React.FC<Props> = ({
  date,
  activeMonth,
  activeDay,
  isShift,
}) => {
  const { isDesktop } = useWidth();

  const day = useMemo(() => getDate(date), [date]);

  const isCurrentMonth = useMemo(
    () => getMonth(date) === activeMonth,
    [activeMonth, date],
  );

  const isCurrentDate = useMemo(
    () => isCurrentMonth && day === activeDay,
    [activeDay, day, isCurrentMonth],
  );

  return (
    <CalendarCell
      className={clsx(
        isCurrentMonth && s["root_current-month"],
        isCurrentDate && s["root_active"],
        isShift && s["root_shift"],
      )}
    >
      <Typography
        typographyType={
          isDesktop ? TypographyType.BODY_MD : TypographyType.CAPS_SM
        }
        typographyWeight={
          isDesktop ? TypographyWeight._700 : TypographyWeight._500
        }
      >
        {day}
      </Typography>
    </CalendarCell>
  );
};

export default memo(CalendarCellDay);

import React from "react";

import WidgetCarouselStories from "../WidgetCarouselStories";
import WidgetDMS from "../WidgetDMS";
import WidgetMoney from "../WidgetMoney";
import WidgetRequests from "../WidgetRequests";
import WidgetSchedule from "../WidgetSchedule";
import WidgetSearch from "../WidgetSearch";
import WidgetTasks from "../WidgetTasks";

import * as s from "./WidgetsDashboard.module.scss";

const WidgetsDashboard: React.FC = () => {
  return (
    <div className={s["root"]}>
      <WidgetSearch />
      <WidgetCarouselStories />
      <div className={s["grid"]}>
        <WidgetRequests className={s["grid__requests"]} />
        <WidgetTasks className={s["grid__tasks"]} />
        <WidgetDMS className={s["grid__dms"]} />
        <WidgetMoney className={s["grid__money"]} />
        <WidgetSchedule className={s["grid__schedule"]} />
      </div>
    </div>
  );
};

export default WidgetsDashboard;

import { memo } from "react";

import IconBase, { type IconBaseProps } from "./IconBase";

const EyeOpenIcon: React.FC<IconBaseProps> = (props: IconBaseProps) => {
  return (
    <IconBase {...props} height="24" viewBox="0 0 24 24" width="24">
      <path
        d="M23.159 11.543c-.035-.075-.856-1.899-2.67-3.713-2.427-2.423-5.486-3.705-8.861-3.705-3.375 0-6.434 1.282-8.859 3.705C.955 9.644.134 11.467.096 11.543a1.133 1.133 0 0 0 0 .915c.035.076.856 1.899 2.671 3.713 2.427 2.423 5.486 3.704 8.86 3.704 3.376 0 6.435-1.28 8.858-3.704 1.815-1.814 2.636-3.637 2.671-3.713a1.132 1.132 0 0 0 .003-.915Zm-4.318 3.094c-2.013 1.982-4.44 2.988-7.213 2.988-2.774 0-5.2-1.006-7.21-2.989A12.219 12.219 0 0 1 2.394 12a12.23 12.23 0 0 1 2.022-2.635c2.011-1.984 4.437-2.99 7.21-2.99 2.775 0 5.2 1.006 7.21 2.99.792.782 1.472 1.669 2.023 2.635a12.23 12.23 0 0 1-2.022 2.636l.003.001Zm-7.213-6.762a4.125 4.125 0 1 0 0 8.25 4.125 4.125 0 0 0 0-8.25Zm0 6a1.875 1.875 0 1 1 0-3.75 1.875 1.875 0 0 1 0 3.75Z"
        fill="currentColor"
      />
    </IconBase>
  );
};

export default memo(EyeOpenIcon);

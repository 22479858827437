import { useObserver } from "mobx-react-lite";

import GlobalAuthStore, {
  AuthStage,
} from "@repo/data/store/single/GlobalAuthStore";

function useAuthStage() {
  const globalAuthStore = GlobalAuthStore.getInstance();

  return useObserver(() => ({
    isAuth: globalAuthStore.stage === AuthStage.AUTHENTICATED,
  }));
}

export default useAuthStage;

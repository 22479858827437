// extracted by mini-css-extract-plugin
var _1 = "root__hkZxM";
var _2 = "root__navigation__gf4AD";
var _3 = "root__navigation_disabled__Z1VB1";
var _4 = "root__navigation_next__Ez6t_";
var _5 = "root__navigation_prev__xAYRA";
var _6 = "root__pagination-placeholder__mH6Jp";
var _7 = "root__slide__k1xwo";
var _8 = "root__swiper__AiHOZ";
export { _1 as "root", _2 as "root__navigation", _3 as "root__navigation_disabled", _4 as "root__navigation_next", _5 as "root__navigation_prev", _6 as "root__pagination-placeholder", _7 as "root__slide", _8 as "root__swiper" }

import { PropsWithChildren, useMemo } from "react";
import { createPortal } from "react-dom";

import { PortalSelector } from "./config";

export type PortalProps = {
  portalSelector: PortalSelector;
};

const Portal: React.FC<PropsWithChildren<PortalProps>> = ({
  portalSelector,
  children,
}: PropsWithChildren<PortalProps>) => {
  const container = useMemo<HTMLElement | null>(() => {
    return document.getElementById(portalSelector);
  }, [portalSelector]);

  return container ? createPortal(children, container) : children;
};

export default Portal;

import { memo } from "react";

import IconBase, { type IconBaseProps } from "./IconBase";

const PlusIcon: React.FC<IconBaseProps> = (props: IconBaseProps) => {
  return (
    <IconBase {...props} height="24" viewBox="0 0 24 24" width="24">
      <path
        d="M19 11h-6V5c0-.26522-.1054-.51957-.2929-.70711C12.5196 4.10536 12.2652 4 12 4c-.2652 0-.5196.10536-.7071.29289C11.1054 4.48043 11 4.73478 11 5v6H5c-.26522 0-.51957.1054-.70711.2929C4.10536 11.4804 4 11.7348 4 12c0 .2652.10536.5196.29289.7071C4.48043 12.8946 4.73478 13 5 13h6v6c0 .2652.1054.5196.2929.7071S11.7348 20 12 20c.2652 0 .5196-.1054.7071-.2929S13 19.2652 13 19v-6h6c.2652 0 .5196-.1054.7071-.2929S20 12.2652 20 12c0-.2652-.1054-.5196-.2929-.7071S19.2652 11 19 11Z"
        fill="currentColor"
      />
    </IconBase>
  );
};

export default memo(PlusIcon);

import { memo, useId } from "react";

import IconBase, { type IconBaseProps } from "./IconBase";

const House3dIcon: React.FC<IconBaseProps> = (props: IconBaseProps) => {
  const imgId = useId();
  const patternId = useId();

  return (
    <IconBase {...props} height="32" viewBox="0 0 32 32" width="32">
      <rect fill={`url(#${patternId})`} height="32" width="32" />
      <defs>
        <pattern
          height="1"
          id={patternId}
          patternContentUnits="objectBoundingBox"
          width="1"
        >
          <use transform="scale(0.00195312)" xlinkHref={`#${imgId}`} />
        </pattern>
        <image
          height="512"
          id={imgId}
          width="512"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARtSURBVHgB3ZVLTFxVGMf/59w7986dNwNTBAFpU6E8ik0UNKmNHRuNcaEuTBM3xp1LF250pWsXhsRNY3wsTAzExGIfxBDKgKk0yoIaSqEI7UABbZmhMMwwc5/H784wta0UQXe9yc3Nueec/+/7/uf77gX2cInTiYgQwrOXPWy3C8X4eKMY+CkhNvQN3tX5InstntrNvl0BROJSI4YSCTF1uRGcxnXNSd75zFH21uvL/xsgzozsF6OJIcxMNMLM0gsHkBSgrmWWdXSdYO+eXPjPADEwcIRsOYvZK3Uin0FidZ02ODge8oEzBU4tZRJ9Is66P0zuGWCcHzgiDw4NY24ybBeyuOAwXK+pgmGaaL+5jGPMJrc8EPsOJO2G9rj2EMi2AHNwMC719X+P5LWISeIj4SjSTQfh8Qfh2DY2N9bRcHUKnalbYEwCKhuTUsPhuHbq4+S/AqwLiXf4d6e/wsIMy+U3MVrfiEJbG9SKaBHgCwXh9XmRvjkPfr4fh+dnwS0BFn48qYeb4jV93cmHAqyhkbf5tz1fY3GO5wqbuNjUBtHaDrUyBjUchr8igkhVFIqqwCD4/MQECufOoWN2GsjngXBdktW2xGt6/obcBYizP77n9PZ2s1sLyNDi0aefg3yo5a54sDKKEAFUzQtJoqO2bGQz61icuYY7fT+gdfI3sFwOCNUkjermeEvfqSJEKgM+Sq98zrLp6tVsHpeOvgDPoVZo+6rhJWvC+2IIV1ZA8/kgK9TIru+cQZY90EIhGLEqTGc2EFhegrmRjuT09We/WF768j7AB8Lbv+L3xC+//MpjypPN0Cqq4AuEEIlWIBwJQ1NVEpRJmJdSZ6x4u++0YAAsFsOMXoB9fe7KWir1Zm82u/aPM5gaGzt+J7WWcCtDPdOHqulpaJoKhUuQKGIn6IP16WfUCcDF33PQKBnJNpHKWKiVU/ArApsFI37spfhwWVO+FyAHIghwL/lrIpBbRwUKkIQFblMcuoPNlT+h6yapMnwzWUBNSEKVZOPnOQvvd6qor6mE4h72vZoPjrjrK3nt9XohGzqJG1sl5sDWLVgmjYWEEwc1VAY5eMFAkFIJ+pwt++QdADRUvBIUjweMLBFUFQ53XXQgCGA5EgzDBBcCA1d11Df44FvdxK9LNtqfF3AcgQev+wBeOjALboFw2LTWKZhgJAa6BW22FEYAAzITqPVwRGk+oErYL+vwe2TYNgVhWTtlAPdrTOIOdFq4spYpVQFzo6NeCgVgk0W6mcersSS4JFEwDB3tEvSsD3ZE2xlADlOknL43BKCoZV+pit3E6U8Gw7WL5mQvHeiBpuJcuVzhZu24GexgUT6Tecomn4VjY8MyUCCfGCshXHsLJKDqeVi2VQSKLTgr5lkCOabdRYPhbQGTY7+84W7kVPdGOgWVVOWiSElMlsmSxRsot08ps9Le8tMBO0mPT7YF3P5jsYeMHecSh2TpiPn9TKP3Ci+1fJaqaz59u3hOnMtw15U7u9h9xVz4DTxS118/ruQJOfvvVAAAAABJRU5ErkJggg=="
        />
      </defs>
    </IconBase>
  );
};

export default memo(House3dIcon);

import clsx from "clsx";
import { memo } from "react";

import DetailItem from "../DetailItem";

import * as s from "./DetailList.module.scss";

export type DetailListProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
};

const DetailList: React.FC<DetailListProps> = ({
  className,
}: DetailListProps) => {
  return (
    <div className={clsx(s["details"], className)}>
      <DetailItem text="vyatkina@hello-hr.ru" title="Электронная почта" />
      <DetailItem text="+7 964 999-99-99" title="Телефон" />
      <DetailItem text="@vyatkina85" title="Telegram" />
      <DetailItem text="ООО «Любимая компания»" title="Юридическое лицо" />
      <DetailItem text="Специалист" title="Должность" />
      <DetailItem text="19 августа" title="Дата рождения" />
      <DetailItem text="0422" title="Табельный номер" />
    </div>
  );
};

export default memo(DetailList);

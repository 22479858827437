import { createContextLocalStore } from "../createContextLocalStore";

import AvatarChangeStore from "./AvatarChangeStore";

const { Provider, useStore } = createContextLocalStore(AvatarChangeStore);

export {
  Provider as AvatarChangeStoreProvider,
  useStore as useAvatarChangeStore,
  AvatarChangeStore,
};

import React, { memo } from "react";

import WidgetCard, {
  WidgetCardColor,
  WidgetCardNumberColor,
} from "../components/WidgetCard";

import icon from "./icons/icon.png";
import iconSmall from "./icons/icon_small.png";

type Props = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
};

const WidgetRequests: React.FC<Props> = ({ className }) => {
  return (
    <WidgetCard
      isLuminosity
      cardNumbers={[
        {
          value: 4,
          title: "открыто",
          color: WidgetCardNumberColor.PRIMARY,
        },
        {
          value: 2,
          title: "на уточнении",
          color: WidgetCardNumberColor.SECONDARY,
        },
      ]}
      className={className}
      color={WidgetCardColor.MAIN}
      iconLarge={icon}
      iconSmall={iconSmall}
      linkTo="/profile"
      title="Заявки"
    />
  );
};

export default memo(WidgetRequests);

import clsx from "clsx";
import { memo } from "react";

import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import useWidth from "@app/hooks/useWidth";

import * as s from "./DetailItem.module.scss";

export type DetailItemProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Заголовок. */
  title: string;
  /** Текст. */
  text: string;
};

const DetailItem: React.FC<DetailItemProps> = ({
  className,
  title,
  text,
}: DetailItemProps) => {
  const { isPhone } = useWidth();

  return (
    <div className={clsx(s["detail"], className)}>
      <Typography
        Component="p"
        className={s["detail__title"]}
        typographyType={
          isPhone ? TypographyType.BODY_SM : TypographyType.BODY_MD
        }
        typographyWeight={TypographyWeight._400}
      >
        {title}
      </Typography>

      <Typography
        Component="p"
        typographyType={
          isPhone ? TypographyType.BODY_MD : TypographyType.BODY_LG
        }
        typographyWeight={TypographyWeight._500}
      >
        {text}
      </Typography>
    </div>
  );
};

export default memo(DetailItem);

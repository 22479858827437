import clsx from "clsx";
import { memo } from "react";

import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import * as s from "./TabDetail.module.scss";

export type TabDetailProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Заголовок. */
  title: string;
  /** Текст. */
  text: string;
};

const TabDetail: React.FC<TabDetailProps> = ({
  className,
  title,
  text,
}: TabDetailProps) => {
  return (
    <div className={clsx(s["detail"], className)}>
      <Typography
        Component="p"
        className={s["detail__title"]}
        typographyType={TypographyType.BODY_MD}
        typographyWeight={TypographyWeight._400}
      >
        {title}
      </Typography>

      <Typography
        Component="p"
        typographyType={TypographyType.BODY_MD}
        typographyWeight={TypographyWeight._500}
      >
        {text}
      </Typography>
    </div>
  );
};

export default memo(TabDetail);

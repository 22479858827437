import clsx from "clsx";
import { forwardRef, memo, type PropsWithChildren } from "react";

import ButtonDefault, { ButtonDefaultProps, ButtonStyleProps } from "../Button";
import { ButtonColor } from "../config";

import * as s from "./ButtonText.module.scss";

export type ButtonTextProps = ButtonDefaultProps &
  Pick<ButtonStyleProps, "color">;

const ButtonText = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<ButtonTextProps>
>(
  (
    {
      className,
      color = ButtonColor.PRIMARY,
      disabled,
      children,
      ...props
    }: PropsWithChildren<ButtonTextProps>,
    ref,
  ) => {
    const classNames = clsx(
      s["button"],
      s[`button_color-${color}`],
      disabled && s["button_disabled"],
      className,
    );

    return (
      <ButtonDefault
        ref={ref}
        className={classNames}
        disabled={disabled}
        {...props}
      >
        {children}
      </ButtonDefault>
    );
  },
);

ButtonText.displayName = "ButtonText";

export default memo(ButtonText);

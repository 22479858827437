// extracted by mini-css-extract-plugin
var _1 = "typography__PfRmn";
var _2 = "typography_body-lg__wpj7s";
var _3 = "typography_body-md__yCFWh";
var _4 = "typography_body-sm__UnVHn";
var _5 = "typography_btn-md__bVkSK";
var _6 = "typography_btn-sm__nuMuB";
var _7 = "typography_caps-lg__w2Cl3";
var _8 = "typography_caps-md__B3Z8k";
var _9 = "typography_caps-sm__XODn8";
var _a = "typography_caption__fq13K";
var _b = "typography_caption-extra-sm__v9zOc";
var _c = "typography_caption-sm__v3Fb4";
var _d = "typography_h1__RYleY";
var _e = "typography_h2__GPH2D";
var _f = "typography_h3__VxFxA";
var _10 = "typography_h4__nAbfW";
var _11 = "typography_numbers-lg__vcqfG";
var _12 = "typography_numbers-md___EVcF";
var _13 = "typography_weight-100__I6i1Y";
var _14 = "typography_weight-200__y5NGj";
var _15 = "typography_weight-300__s6KRW";
var _16 = "typography_weight-400__lGdEN";
var _17 = "typography_weight-500__EzDWE";
var _18 = "typography_weight-600__HJzB4";
var _19 = "typography_weight-700__ymwsj";
var _1a = "typography_weight-800__yWllw";
var _1b = "typography_weight-900__kDuXu";
export { _1 as "typography", _2 as "typography_body-lg", _3 as "typography_body-md", _4 as "typography_body-sm", _5 as "typography_btn-md", _6 as "typography_btn-sm", _7 as "typography_caps-lg", _8 as "typography_caps-md", _9 as "typography_caps-sm", _a as "typography_caption", _b as "typography_caption-extra-sm", _c as "typography_caption-sm", _d as "typography_h1", _e as "typography_h2", _f as "typography_h3", _10 as "typography_h4", _11 as "typography_numbers-lg", _12 as "typography_numbers-md", _13 as "typography_weight-100", _14 as "typography_weight-200", _15 as "typography_weight-300", _16 as "typography_weight-400", _17 as "typography_weight-500", _18 as "typography_weight-600", _19 as "typography_weight-700", _1a as "typography_weight-800", _1b as "typography_weight-900" }

import clsx from "clsx";
import { memo } from "react";

import Button, { ButtonSize } from "@repo/ui/Button";
import Icon, { IconSize, IconType } from "@repo/ui/Icon";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import Description from "./Description";

import * as s from "./NotificationItem.module.scss";

type NotificationItemButtonProps = {
  /** Ссылка на уведомление. */
  link: string;
  /** Текст кнопки. */
  text: string;
};

export type NotificationItemProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Дата уведомления. */
  date: string;
  /** Заголовок уведомления. */
  title?: string;
  /** Описание уведомления. */
  description?: string;
  /** Кнопка уведомления. */
  button?: NotificationItemButtonProps;
  /** Флаг прочтения уведомления. */
  isRead?: boolean;
  /** Ссылка на иконку */
  iconUrl?: string;
};

const NotificationItem: React.FC<NotificationItemProps> = ({
  className,
  date,
  title,
  description,
  button,
  iconUrl,
  isRead,
}) => {
  return (
    <div className={clsx(s["item"], className)}>
      <div className={s["item__icon"]}>
        {iconUrl ? (
          <img alt={`Уведоиление "${title}"`} src={iconUrl} />
        ) : (
          <Icon size={IconSize.M} type={IconType.HAND} />
        )}
      </div>

      <div className={s["item__content"]}>
        <div className={s["item__header"]}>
          <Typography
            Component="p"
            className={s["item__date"]}
            typographyType={TypographyType.CAPTION}
            typographyWeight={TypographyWeight._500}
          >
            {date}
          </Typography>
          {isRead ? (
            <Icon
              className={s["item__icon-read"]}
              size={IconSize.XS}
              type={IconType.DOUBLE_CHECK}
            />
          ) : (
            <Button.Text
              after={
                <Icon
                  className={s["item__icon-eye"]}
                  size={IconSize.XS}
                  type={IconType.EYE_OPEN}
                />
              }
              className={s["item__read"]}
            >
              <span className={s["item__read-text"]}>Прочитать</span>
            </Button.Text>
          )}
        </div>

        {title && (
          <Typography
            Component="p"
            className={s["item__title"]}
            typographyType={TypographyType.BODY_SM}
            typographyWeight={TypographyWeight._500}
          >
            {title}
          </Typography>
        )}

        {description && <Description text={description} />}

        {button && (
          <Button.Base className={s["item__button"]} size={ButtonSize.MEDIUM}>
            {button.text}
          </Button.Base>
        )}
      </div>
    </div>
  );
};

export default memo(NotificationItem);

import { user } from "@repo/api/types";

import { UserType } from "./types";

export const normalizeUser = (data: user.UserCurrentApi): UserType => ({
  id: data.id,
  username: data.username,
  fullName: data.full_name,
  employeeNumber: data.employee_number,
  phone: data.phone,
  email: data.email,
});

import { memo } from "react";

import IconBase, { type IconBaseProps } from "./IconBase";

const DotsIcon: React.FC<IconBaseProps> = (props: IconBaseProps) => {
  return (
    <IconBase {...props} height="24" viewBox="0 0 24 24" width="24">
      <path
        d="M13.5 12c0 .2967-.088.5867-.2528.8334-.1648.2466-.3991.4389-.6732.5524-.2741.1136-.5757.1433-.8666.0854-.291-.0579-.5583-.2008-.7681-.4105-.2097-.2098-.3526-.4771-.4105-.7681-.0579-.2909-.0282-.5925.0854-.8666.1135-.2741.3058-.5084.5524-.6732.2467-.1648.5367-.2528.8334-.2528.3978 0 .7794.158 1.0607.4393S13.5 11.6022 13.5 12Zm-7.875-1.5c-.29667 0-.58668.088-.83335.2528-.24668.1648-.43894.3991-.55247.6732-.11353.2741-.14324.5757-.08536.8666.05788.291.20074.5583.41052.7681.20978.2097.47705.3526.76803.4105.29097.0579.59257.0282.86666-.0854.27408-.1135.50835-.3058.67318-.5524.16482-.2467.25279-.5367.25279-.8334 0-.3978-.15803-.7794-.43934-1.0607-.2813-.2813-.66283-.4393-1.06066-.4393Zm12.75 0c-.2967 0-.5867.088-.8334.2528-.2466.1648-.4389.3991-.5524.6732-.1135.2741-.1433.5757-.0854.8666.0579.291.2008.5583.4105.7681.2098.2097.4771.3526.7681.4105.2909.0579.5925.0282.8666-.0854.2741-.1135.5084-.3058.6732-.5524.1648-.2467.2528-.5367.2528-.8334 0-.3978-.158-.7794-.4393-1.0607S18.7728 10.5 18.375 10.5Z"
        fill="currentColor"
      />
    </IconBase>
  );
};

export default memo(DotsIcon);

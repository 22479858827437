import React, { memo } from "react";

import WidgetCard, {
  WidgetCardColor,
  WidgetCardNumberColor,
} from "../components/WidgetCard";

import icon from "./icons/icon.png";
import iconSmall from "./icons/icon_small.png";

type Props = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
};

const WidgetTasks: React.FC<Props> = ({ className }) => {
  return (
    <WidgetCard
      cardNumbers={[
        {
          value: 2,
          title: "задачи на выполнение",
          color: WidgetCardNumberColor.PRIMARY,
        },
      ]}
      className={className}
      color={WidgetCardColor.SECONDARY}
      iconLarge={icon}
      iconSmall={iconSmall}
      linkTo="/profile"
      title="Задачи"
    />
  );
};

export default memo(WidgetTasks);

// extracted by mini-css-extract-plugin
var _1 = "personal__OlSOj";
var _2 = "personal__avatar__eTAPl";
var _3 = "personal__content__sBGcZ";
var _4 = "personal__controls__nRUHr";
var _5 = "personal__details__Q4Vzn";
var _6 = "personal__edit__VUuhC";
var _7 = "personal__status__iwwKU";
var _8 = "personal__title__Xif85";
export { _1 as "personal", _2 as "personal__avatar", _3 as "personal__content", _4 as "personal__controls", _5 as "personal__details", _6 as "personal__edit", _7 as "personal__status", _8 as "personal__title" }

import { createContextLocalStore } from "../createContextLocalStore";

import AuthFormStore from "./AuthFormStore";

const { Provider, useStore } = createContextLocalStore(AuthFormStore);

export {
  Provider as AuthFormStoreProvider,
  useStore as useAuthFormStore,
  AuthFormStore,
};

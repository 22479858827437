import { RefObject, useEffect, useRef, useState } from "react";

type TextWithHideOptions = {
  /** Количество строк */
  countLine: number;
};

/**
 * Хук для определения, умещается ли текст в элементе
 *
 * @param ref - ссылка на элемент
 * @param countLine - количество строк
 */
function useTextWithHide<E extends HTMLElement>(
  ref: RefObject<E>,
  { countLine }: TextWithHideOptions,
): boolean {
  const [withHide, setWithHide] = useState(false);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const lineHeight = parseFloat(getComputedStyle(ref.current).lineHeight);

    setWithHide(Math.round(lineHeight * countLine) < ref.current.offsetHeight);
  }, [countLine, ref]);

  return withHide;
}

/**
 * Хук для определения, умещается ли текст в элементе
 *
 * @param options - опции
 */
export function useTextWithHideRef<E extends HTMLElement>(
  options: TextWithHideOptions,
): [RefObject<E>, boolean] {
  const ref = useRef<E>(null);

  const withHide = useTextWithHide(ref, options);

  return [ref, withHide];
}

export default useTextWithHide;

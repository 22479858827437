import clsx from "clsx";
import * as React from "react";

import IconHand from "../Icon/components/IconHand";

import { LoaderSize } from "./config";

import * as s from "./Loader.module.scss";

type LoaderProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Размер */
  size?: LoaderSize;
  /** Абсолютное позиционирование по центру */
  isAbsolute?: boolean;
};

const Loader: React.FC<LoaderProps> = ({
  className,
  size = LoaderSize.S,
  isAbsolute,
}: LoaderProps) => {
  return (
    <div
      className={clsx(
        s.loader,
        s[`loader_size-${size}`],
        isAbsolute && s["loader_absolute"],
        className,
      )}
    >
      <IconHand
        classNameBob={s.loader__bob}
        classNameHand={s.loader__hand}
        size={null}
      />
    </div>
  );
};

export default React.memo(Loader);

type LocalStorageGetOptions<V extends string> = {
  /**
   * Значение по умолчанию
   */
  defaultValue: V | (() => V);
  /**
   * Проверка значения
   */
  checkValue?: (value: string | null) => value is V;
};

/**
 * Локальное хранилище
 */
const LocalStorage = {
  /**
   * Получение значения из localStorage
   *
   * @param key - ключ
   * @param options - опции
   *
   * @returns - значение
   */
  get<V extends string>(
    key: string,
    {
      defaultValue,
      checkValue = (value): value is V => Boolean(value),
    }: LocalStorageGetOptions<V>,
  ): V {
    const value = localStorage.getItem(key);

    if (checkValue(value)) {
      return value;
    }

    return typeof defaultValue === "function" ? defaultValue() : defaultValue;
  },

  /**
   * Установка значения в localStorage
   *
   * @param key - ключ
   * @param value - значение
   */
  set(key: string, value: string) {
    localStorage.setItem(key, value);
  },
};

export default LocalStorage;

import clsx from "clsx";
import { memo } from "react";

import { IconType } from "@repo/ui/Icon";

import useWidth from "@app/hooks/useWidth";

import MenuGroup from "./MenuGroup";
import MenuItem from "./MenuItem";

import * as s from "./Menu.module.scss";

export type MenuProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
};

interface IMenuComponent extends React.FC<MenuProps> {
  /** Вложенный компонент MenuItem. */
  Item: typeof MenuItem;
  /** Вложенный компонент MenuGroup. */
  Group: typeof MenuGroup;
}

const Menu: IMenuComponent = ({ className }: MenuProps) => {
  const { isPhone } = useWidth();
  return (
    <div className={clsx(s["menu"], className)}>
      <Menu.Group id="Основные сервисы" title="Основные сервисы">
        <Menu.Item
          icon={IconType.NEWS_3D}
          id="Новости"
          link="/news"
          title="Новости"
        />
        {!isPhone && (
          <Menu.Item
            counter={{
              count: "2",
            }}
            icon={IconType.CHECK_PAPER_3D}
            id="Задачи"
            link="/tasks"
            title="Задачи"
          />
        )}
        {!isPhone && (
          <Menu.Item
            counter={{
              count: "4",
              allCount: "2",
            }}
            icon={IconType.ARROW_DOWN_3D}
            id="Заявки"
            link="/postulations"
            title="Заявки"
          />
        )}
        <Menu.Item
          icon={IconType.PROFILE_3D}
          id="Сотрудники"
          link="/teams"
          title="Сотрудники"
        />
      </Menu.Group>

      <Menu.Group id="Любимые сервисы" title="Любимые сервисы">
        <Menu.Item
          icon={IconType.MONEY_3D}
          id="Доходы"
          link="/money"
          title="Доходы"
        />
        <Menu.Item
          icon={IconType.CALENDAR_3D}
          id="График работы"
          link="/schedule"
          title="График работы"
        />
        <Menu.Item
          icon={IconType.TREE_3D}
          id="Отпуска"
          link="/vacations"
          title="Отпуска"
        />
        <Menu.Item
          icon={IconType.DOCTOR_3D}
          id="Страховка"
          link="/insurance"
          title="Страховка"
        />
      </Menu.Group>
      <Menu.Group id="Прочее" title="Прочее">
        <Menu.Item
          icon={IconType.REPORTS_3D}
          id="Приложения"
          link="/apps"
          title="Приложения"
        />
        <Menu.Item
          icon={IconType.COINS_3D}
          id="Магазин"
          link="/store"
          title="Магазин"
        />
        <Menu.Item
          icon={IconType.SETTINGS_3D}
          id="Cервисы"
          link="/services"
          title="Cервисы"
        />
        <Menu.Item
          icon={IconType.INFO_3D}
          id="Помощь"
          link="/help"
          title="Помощь"
        />
        <Menu.Item
          icon={IconType.SUPPORT_3D}
          id="Поддержка"
          link="/support"
          title="Поддержка "
        />
      </Menu.Group>
    </div>
  );
};

Menu.Item = MenuItem;
Menu.Group = MenuGroup;

export default memo(Menu);

import { Link } from "@tanstack/react-router";
import clsx from "clsx";
import { memo } from "react";

import logo from "../../images/hello-hr.svg";
import Typography, { TypographyType, TypographyWeight } from "../Typography";

import * as s from "./Logo.module.scss";

export type LogoProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Заголовок логотипа. */
  title: string;
  /** Подзаголовок логотипа. */
  subtitle?: string;
  /** Включить ссылку на главную */
  withLinkMain?: boolean;
};

const Logo: React.FC<LogoProps> = ({
  className,
  title,
  subtitle,
  withLinkMain = true,
}: LogoProps) => {
  const content = (
    <>
      {" "}
      <img alt="Логотип компании" className={s["logo__image"]} src={logo} />
      <div className={s["logo__content"]}>
        <Typography
          className={clsx(!subtitle && s["logo__title"])}
          typographyType={TypographyType.CAPS_LG}
          typographyWeight={TypographyWeight._700}
        >
          {title}
        </Typography>

        {Boolean(subtitle) && (
          <Typography
            typographyType={TypographyType.CAPS_SM}
            typographyWeight={TypographyWeight._700}
          >
            {subtitle}
          </Typography>
        )}
      </div>
    </>
  );

  if (withLinkMain) {
    return (
      <Link className={clsx(s["logo"], className)} to={"/"}>
        {content}
      </Link>
    );
  }

  return <div className={clsx(s["logo"], className)}>{content}</div>;
};

export default memo(Logo);

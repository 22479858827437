import { memo } from "react";

import IconBase, { type IconBaseProps } from "./IconBase";

type Props = IconBaseProps & {
  classNameBob?: string;
  classNameHand?: string;
};

const IconHand: React.FC<Props> = ({
  classNameBob,
  classNameHand,
  ...props
}: Props) => {
  return (
    <IconBase {...props} height="44" viewBox="0 0 44 44" width="44">
      <path
        className={classNameBob}
        d="M14.464 37.585a1.723 1.723 0 0 0-1.685-1.729 2.476 2.476 0 0 1-2.244-1.705l-.002-.005a1.72 1.72 0 0 0-3.263 1.088l.776-.258-.777.255a5.913 5.913 0 0 0 5.457 4.064h.023a1.717 1.717 0 0 0 1.715-1.71ZM18.265 8.324a1.723 1.723 0 0 0 2.03 1.305 2.477 2.477 0 0 1 2.571 1.156l.003.005a1.72 1.72 0 0 0 2.934-1.795l-.698.427.7-.424a5.913 5.913 0 0 0-6.231-2.732l-.011.003-.011.002a1.717 1.717 0 0 0-1.287 2.053Z"
        fill="#fff"
        stroke="currentColor"
        strokeWidth="1.636"
      />
      <g className={classNameHand}>
        <path
          d="M33.528 28.402a7.925 7.925 0 0 1-12.242 6.61l-5.721-3.715a1.213 1.213 0 0 1 1.309-2.043l3.205 2.082a.901.901 0 1 0 .982-1.513l-3.192-2.073-.02-.013-2.818-1.83a1.212 1.212 0 1 1 1.322-2.034l6.03 3.916a.902.902 0 0 0 .981-1.513l-8.158-5.298a1.213 1.213 0 0 1 1.322-2.034l2.1 1.364.028.018 6.03 3.916a.903.903 0 1 0 .982-1.512l-6.047-3.928a1.212 1.212 0 0 1 .426-2.192c.314-.067.643-.006.913.169l8.867 5.759a.901.901 0 0 0 1.39-.688l.25-3.263a1.044 1.044 0 0 1 .38-.73 1.029 1.029 0 0 1 .79-.236 1.052 1.052 0 0 1 .936 1.052l-.045 9.73Z"
          fill="#fff"
        />
        <path
          clipRule="evenodd"
          d="M32.84 15.829a2.856 2.856 0 0 0-3.17 2.62l-.135 1.75-7.594-4.933a3.02 3.02 0 0 0-4.622 2.051 3.016 3.016 0 0 0-3.276 5.046 3.017 3.017 0 0 0 .006 5.057l.522.339a3.015 3.015 0 0 0 .012 5.05l5.721 3.716a9.66 9.66 0 0 0 7.322 1.357 9.728 9.728 0 0 0 7.705-9.471l.045-9.729a2.853 2.853 0 0 0-2.537-2.853Zm-.44 16.613a7.925 7.925 0 0 0 1.128-4.04l.045-9.729a1.052 1.052 0 0 0-.936-1.052 1.028 1.028 0 0 0-.79.235 1.045 1.045 0 0 0-.38.731l-.25 3.263a.9.9 0 0 1-1.39.688l-8.867-5.76a1.214 1.214 0 0 0-1.34 2.024l6.048 3.928a.902.902 0 1 1-.982 1.512l-6.03-3.916-.028-.018-2.1-1.364a1.213 1.213 0 0 0-1.322 2.034l8.158 5.298a.902.902 0 0 1-.981 1.513l-6.03-3.916a1.213 1.213 0 0 0-1.322 2.034l2.817 1.83.02.013 3.193 2.073a.902.902 0 1 1-.982 1.513l-3.205-2.082a1.214 1.214 0 0 0-1.31 2.043l5.722 3.716A7.924 7.924 0 0 0 32.4 32.442Z"
          fill="currentColor"
          fillRule="evenodd"
        />
        <path
          d="M33.528 28.402a7.925 7.925 0 0 1-12.242 6.61l-5.721-3.715a1.213 1.213 0 0 1 1.309-2.043l3.205 2.082a.901.901 0 1 0 .982-1.513l-3.192-2.073-.02-.013-2.818-1.83a1.212 1.212 0 1 1 1.322-2.034l6.03 3.916a.902.902 0 0 0 .981-1.513l-8.158-5.298a1.213 1.213 0 0 1 1.322-2.034l2.1 1.364.028.018 6.03 3.916a.903.903 0 1 0 .982-1.512l-6.047-3.928a1.212 1.212 0 0 1 .426-2.192c.314-.067.643-.006.913.169l8.867 5.759a.901.901 0 0 0 1.39-.688l.25-3.263a1.044 1.044 0 0 1 .38-.73 1.029 1.029 0 0 1 .79-.236 1.052 1.052 0 0 1 .936 1.052l-.045 9.73Z"
          stroke="currentColor"
          strokeWidth=".364"
        />
        <path
          clipRule="evenodd"
          d="M32.84 15.829a2.856 2.856 0 0 0-3.17 2.62l-.135 1.75-7.594-4.933a3.02 3.02 0 0 0-4.622 2.051 3.016 3.016 0 0 0-3.276 5.046 3.017 3.017 0 0 0 .006 5.057l.522.339a3.015 3.015 0 0 0 .012 5.05l5.721 3.716a9.66 9.66 0 0 0 7.322 1.357 9.728 9.728 0 0 0 7.705-9.471l.045-9.729a2.853 2.853 0 0 0-2.537-2.853Zm-.44 16.613a7.925 7.925 0 0 0 1.128-4.04l.045-9.729a1.052 1.052 0 0 0-.936-1.052 1.028 1.028 0 0 0-.79.235 1.045 1.045 0 0 0-.38.731l-.25 3.263a.9.9 0 0 1-1.39.688l-8.867-5.76a1.214 1.214 0 0 0-1.34 2.024l6.048 3.928a.902.902 0 1 1-.982 1.512l-6.03-3.916-.028-.018-2.1-1.364a1.213 1.213 0 0 0-1.322 2.034l8.158 5.298a.902.902 0 0 1-.981 1.513l-6.03-3.916a1.213 1.213 0 0 0-1.322 2.034l2.817 1.83.02.013 3.193 2.073a.902.902 0 1 1-.982 1.513l-3.205-2.082a1.214 1.214 0 0 0-1.31 2.043l5.722 3.716A7.924 7.924 0 0 0 32.4 32.442Z"
          stroke="currentColor"
          strokeWidth=".364"
        />
      </g>
    </IconBase>
  );
};

export default memo(IconHand);

import { createContextLocalStore } from "../createContextLocalStore";

import CollapseStore from "./CollapseStore";

const { Provider, useStore } = createContextLocalStore(CollapseStore);

export {
  Provider as CollapseStoreProvider,
  useStore as useCollapseStore,
  CollapseStore,
};

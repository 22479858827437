import { FloatingOverlay, FloatingPortal } from "@floating-ui/react";
import clsx from "clsx";
import { memo, PropsWithChildren } from "react";

import { usePopoverContext } from "../../context";

import * as s from "./PopoverPortal.module.scss";

const PopoverPortal: React.FC<PropsWithChildren> = ({
  children,
}: PropsWithChildren) => {
  const data = usePopoverContext();

  return (
    <FloatingPortal>
      {data.withOverlay ? (
        <FloatingOverlay
          lockScroll
          className={clsx(s["overlay"], data.classNameOverlay)}
        >
          {children}
        </FloatingOverlay>
      ) : (
        children
      )}
    </FloatingPortal>
  );
};

export default memo(PopoverPortal);

import { action, makeObservable, observable } from "mobx";

import { Theme } from "../../../config/theme";
import isThemeValue from "../../../guards/isThemeValue";
import LocalStorage from "../../../storage/LocalStorage";
import getBrowserTheme from "../../../utils/getBrowserTheme";

class ThemeStore {
  private static _STORAGE_KEY = "theme";

  /**
   * Тема
   */
  theme: Theme;

  constructor() {
    this.theme = LocalStorage.get(ThemeStore._STORAGE_KEY, {
      defaultValue: getBrowserTheme,
      checkValue: isThemeValue,
    });

    makeObservable(this, {
      theme: observable,
      setTheme: action.bound,
    });
  }

  /**
   * Является ли тема светлой
   */
  get isLight(): boolean {
    return this.theme === Theme.LIGHT;
  }

  /**
   * Установка темы
   *
   * @param theme - тема
   */
  setTheme(theme: Theme) {
    this.theme = theme;
    LocalStorage.set(ThemeStore._STORAGE_KEY, theme);
  }
}

export default ThemeStore;

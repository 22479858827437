import React, { memo } from "react";

import Button, { ButtonColor, ButtonSize } from "@repo/ui/Button";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import useWidth from "@app/hooks/useWidth";

import WidgetTransition from "../components/WidgetTransition";

import * as s from "./WidgetDMS.module.scss";

type Props = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
};

const WidgetDMS: React.FC<Props> = ({ className }) => {
  const { isDesktop } = useWidth();

  return (
    <WidgetTransition className={className}>
      <div className={s["root"]}>
        <div className={s["root__top"]}>
          <Typography
            typographyType={isDesktop ? TypographyType.H3 : TypographyType.H2}
            typographyWeight={TypographyWeight._700}
          >
            Рекомендуем
          </Typography>
          <Button.Base
            color={ButtonColor.PRIMARY}
            size={isDesktop ? ButtonSize.ADAPTIVE_SMALL : ButtonSize.SMALL}
          >
            Оформить
          </Button.Base>
        </div>
        <div className={s["root__bottom"]}>
          <Typography
            className={s["root__subtitle"]}
            typographyType={
              isDesktop ? TypographyType.CAPS_LG : TypographyType.CAPS_SM
            }
            typographyWeight={TypographyWeight._700}
          >
            ДМС для&nbsp;всей семьи
          </Typography>
          <Typography
            typographyType={
              isDesktop ? TypographyType.CAPTION : TypographyType.CAPTION_SM
            }
            typographyWeight={TypographyWeight._500}
          >
            Успейте оформить страховку с&nbsp;корпоративной скидкой
          </Typography>
        </div>
        <div className={s["root__icon"]} />
      </div>
    </WidgetTransition>
  );
};

export default memo(WidgetDMS);

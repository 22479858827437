import clsx from "clsx";
import React, { memo } from "react";

import * as s from "./CalendarCell.module.scss";

type Props = React.PropsWithChildren & {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
};

const CalendarCell: React.FC<Props> = ({ children, className }) => {
  return <div className={clsx(s["root"], className)}>{children}</div>;
};

export default memo(CalendarCell);

import { action, makeObservable, observable, runInAction } from "mobx";

import { apiWithChecks } from "@repo/api/apiWithChecks";
import { ApiPath } from "@repo/api/fetchApi";
import { ILocalStore } from "@repo/data/store/interfaces/ILocalStore";
import MetaStore, { type Meta } from "@repo/data/store/models/MetaStore";
import GlobalAuthStore from "@repo/data/store/single/GlobalAuthStore";
import GlobalUserStore, {
  normalizeUser,
  type UserType,
} from "@repo/data/store/single/GlobalUserStore";
import { type MaskitoOptions, PHONE_MASK_OPTIONS } from "@repo/ui/Input";

type PrivateFields =
  | "_login"
  | "_password"
  | "_loginMaskOptions"
  | "_errorForm";

class AuthFormStore implements ILocalStore {
  private readonly _metaStore = new MetaStore();

  private _login: string = "";
  private _password: string = "";

  private _loginMaskOptions: MaskitoOptions | null = null;

  private _errorForm: string = "";

  get meta(): Meta {
    return this._metaStore.meta;
  }

  get login(): string {
    return this._login;
  }

  get password(): string {
    return this._password;
  }

  get loginMaskOptions(): MaskitoOptions | null {
    return this._loginMaskOptions;
  }

  get errorForm(): string {
    return this._errorForm;
  }

  constructor() {
    makeObservable<this, PrivateFields>(this, {
      _login: observable,
      _password: observable,
      _loginMaskOptions: observable.ref,
      _errorForm: observable,

      setLogin: action,
      setPassword: action.bound,
      submit: action,
      setLoginMaskOptions: action,
    });
  }

  setLogin(value: string): void {
    this._login = value;

    this._errorForm = "";
  }

  setPassword(value: string): void {
    this._password = value;

    this._errorForm = "";
  }

  setLoginMaskOptions(value: string): void {
    if (!value) {
      this._loginMaskOptions = null;
    }

    if (/^\+7/.test(value)) {
      this._loginMaskOptions = PHONE_MASK_OPTIONS;
    }
  }

  private async _fetchLogin(): Promise<UserType | null> {
    this._metaStore.setLoadingStart();

    const { data, isError } = await apiWithChecks(ApiPath.USER_LOGIN, "POST", {
      data: {
        identifier: this._login,
        password: this._password,
      },
    });

    return runInAction(() => {
      if (!data || isError) {
        this._errorForm = "Неправильный логин или пароль";
        this._metaStore.setLoadedErrorMeta();

        return null;
      }

      this._errorForm = "";
      this._metaStore.setLoadedSuccessMeta();

      return normalizeUser(data.user);
    });
  }

  async submit(): Promise<void> {
    const userData = await this._fetchLogin();

    if (!userData) {
      return;
    }

    GlobalUserStore.getInstance(userData);
    GlobalAuthStore.getInstance().login();
  }

  destroy(): void {
    this._login = "";
    this._password = "";

    this._metaStore.destroy();
  }
}

export default AuthFormStore;

import { memo } from "react";

import IconBase, { type IconBaseProps } from "./IconBase";

const CloseIcon: React.FC<IconBaseProps> = (props: IconBaseProps) => {
  return (
    <IconBase {...props} height="24" viewBox="0 0 24 24" width="24">
      <path
        d="M19.546 17.954c.2113.2113.33.498.33.7969 0 .2988-.1187.5855-.33.7968-.2114.2114-.498.3301-.7969.3301-.2989 0-.5855-.1187-.7969-.3301L12 13.5937l-5.95404 5.9522c-.21135.2113-.49799.33-.79688.33-.29888 0-.58553-.1187-.79687-.33-.21135-.2114-.33008-.498-.33008-.7969 0-.2989.11873-.5855.33008-.7969l5.95409-5.9522-5.95222-5.95404c-.21134-.21135-.33007-.49799-.33007-.79688 0-.29888.11873-.58553.33007-.79687.21135-.21135.49799-.33008.79688-.33008.29888 0 .58553.11873.79687.33008L12 10.4062l5.9541-5.95503c.2113-.21134.498-.33008.7969-.33008.2988 0 .5855.11874.7968.33008.2114.21134.3301.49799.3301.79687 0 .29889-.1187.58554-.3301.79688l-5.954 5.95498 5.9522 5.9541Z"
        fill="currentColor"
      />
    </IconBase>
  );
};

export default memo(CloseIcon);

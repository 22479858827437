export enum ButtonSize {
  /** height: 26px */
  SMALL = "small",
  /** height: 36px */
  ADAPTIVE_SMALL = "adaptive-small",
  /** height: 48px */
  MEDIUM = "medium",
  /** height: 54px */
  ADAPTIVE_MEDIUM = "adaptive-medium",
  /** height: 64px */
  LARGE = "large",
}

export enum ButtonColor {
  /** pink */
  PRIMARY = "primary",
  /** light-violet */
  SECONDARY = "secondary",
  /** white */
  TERTIARY = "tertiary",
  /** text-body */
  DEFAULT = "default",
  /** text-placeholder */
  PLACEHOLDER = "placeholder",
  /** text-light */
  INVERSE = "inverse",
}

export enum ButtonTheme {
  /** default */
  DEFAULT = "default",
  /** bordered */
  BORDERED = "bordered",
}

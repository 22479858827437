declare global {
  interface IDocumentEventMap {
    [EventApiWithChecksName.NOT_AUTHORIZED]: EventApiWithChecksNotAuthorized;
  }
}

/**
 * Перечисление имен событий для базового вызова API.
 */
export enum EventApiWithChecksName {
  /** Событие частичной авторизации. */
  NOT_AUTHORIZED = "api-with-checks:not-authorized",
}

/** Класс события частичной авторизации.
 *
 * @class
 * @extends {CustomEvent<undefined>}
 */
export class EventApiWithChecksNotAuthorized extends CustomEvent<undefined> {
  /**
   * Создает экземпляр события частичной авторизации.
   */
  constructor() {
    super(EventApiWithChecksName.NOT_AUTHORIZED);
  }
}

export type AddEventListenerApiWithChecksType = <
  N extends EventApiWithChecksName,
>(
  name: N,
  listener: (e: IDocumentEventMap[N]) => void,
) => void;

/**
 * Добавляет слушатель событий к объекту Document.
 *
 * @param name - Имя события, на которое необходимо подписаться.
 * @param {function} listener - Функция обратного вызова, которая будет вызвана при возникновении события.
 */
export const addEventListener: AddEventListenerApiWithChecksType = (
  name,
  listener,
) => {
  document.addEventListener(name, listener);
};

export type RemoveApiWithChecksEventListenerType = <
  N extends EventApiWithChecksName,
>(
  name: N,
  listener: (e: IDocumentEventMap[N]) => void,
) => void;

/**
 * Удаляет слушатель событий из объекта Document.
 *
 * @param name - Имя события, от которого необходимо отписаться.
 * @param {function} listener - Функция обратного вызова, которую необходимо удалить.
 */
export const removeEventListener: RemoveApiWithChecksEventListenerType = (
  name,
  listener,
) => {
  document.removeEventListener(name, listener);
};

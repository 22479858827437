export enum TagAngle {
  /** Верхний левый угол */
  TOP_LEFT = "top-left",
  /** Верхний правый угол */
  TOP_RIGHT = "top-right",
  /** Нижний левый угол */
  BOTTOM_LEFT = "bottom-left",
  /** Нижний правый угол */
  BOTTOM_RIGHT = "bottom-right",
}

export enum TagColor {
  /** Gray */
  DEFAULT = "default",
  /** Violet */
  PRIMARY = "primary",
  /** Yellow */
  SECONDARY = "secondary",
  /** Aqua */
  TERTIARY = "tertiary",
}

export enum TagSize {
  /** height: 28px/36px */
  S = "s",
  /** height: 32px/39px */
  M = "m",
}

export type Converter<From, To> = (from: From) => To;

export type MiddlewareFunc<P, R> = (
  /** Тип данных, которые будут прокиданы в каждый middleware */
  params: P,
  /** Функция которая вернет результат, следующего middleware */
  next: Converter<P, R>,
) => R;

/*
 * Запускает middleware поочередно и возвращает конечный результат.
 * Пример combineMiddleware([A,B,C], D)
 * Порядок вызовов A => B => C => D
 */
export function combineMiddleware<P extends Record<string, unknown>, R>(
  funcs: Array<MiddlewareFunc<P, R>>,
  initialFunc: Converter<P, R>,
): <RP extends P>(params: RP) => R {
  return funcs.reduceRight<Converter<P, R>>((next, current) => {
    const func: Converter<P, R> = (p: P): R => current(p, next);

    return func;
  }, initialFunc);
}

import { createFileRoute } from "@tanstack/react-router";

import Loader, { LoaderSize } from "@repo/ui/Loader";

import WidgetsDashboard from "@app/pages/auth/widgets/WidgetsDashboard";

const AuthIndexPage: React.FC = () => {
  return <WidgetsDashboard />;
};

export const Route = createFileRoute("/_auth/")({
  component: AuthIndexPage,
  pendingComponent: () => <Loader isAbsolute size={LoaderSize.L} />,
});

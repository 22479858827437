import { setDefaultOptions } from "date-fns";
import { createRoot } from "react-dom/client";

import App from "./App";
import "./global.scss";
import "./store/connections";
import { initMobX } from "./store/initMobx";

initMobX();

setDefaultOptions({
  weekStartsOn: 1,
});

const root = document.getElementById("root");

if (root) {
  createRoot(root).render(<App />);
}

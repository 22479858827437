import { createFileRoute } from "@tanstack/react-router";

import Loader, { LoaderSize } from "@repo/ui/Loader";

import WidgetsDashboard from "@app/pages/auth/widgets/WidgetsDashboard";

export const Route = createFileRoute("/_auth/store")({
  component: () => <WidgetsDashboard />,
  pendingComponent: () => <Loader isAbsolute size={LoaderSize.L} />,
});

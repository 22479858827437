import clsx from "clsx";
import { memo } from "react";

import Button, { ButtonSize, ButtonTheme } from "@repo/ui/Button";
import Counter, { CounterSize } from "@repo/ui/Counter";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import NotificationItem from "../NotificationItem";
import ScrollLayout from "../ScrollLayout";

import iconNotificationTest from "./__mock__/img/icon-notification-test.png";

import * as s from "./NotificationContent.module.scss";

export type NotificationContentProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Количество уведомлений. */
  count: number;
};

const NotificationContent: React.FC<NotificationContentProps> = ({
  className,
  count,
}: NotificationContentProps) => {
  return (
    <div className={clsx(s["content"], className)}>
      <div className={s["content__header"]}>
        <Typography
          Component="p"
          className={s["content__title"]}
          typographyType={TypographyType.H4}
          typographyWeight={TypographyWeight._700}
        >
          Уведомления{" "}
          <Counter
            count={count.toString()}
            size={CounterSize.L}
            typographyType={TypographyType.CAPS_SM}
            typographyWeight={TypographyWeight._700}
          />
        </Typography>
        <Button.Base
          className={s["content__button"]}
          size={ButtonSize.SMALL}
          theme={ButtonTheme.BORDERED}
        >
          Прочитать все
        </Button.Base>
      </div>

      <ScrollLayout className={s["content__list"]}>
        <NotificationItem
          button={{
            link: "/",
            text: "Пройти курс",
          }}
          date="17 июня, 12:31"
          description="Пройдите обязательный курс подтверждения квалификации"
          iconUrl={iconNotificationTest}
          title="Вам доступен новый курс"
        />
        <NotificationItem
          button={{
            link: "/",
            text: "Перейти",
          }}
          date="Вчера, 12:31"
          description="Подпишите лист ознакомления с ЛНА «Положение об электронном документообороте» до конца текущего рабочего дня."
          title="Вам назначена задача №111."
        />
        <NotificationItem
          date="17 июня, 12:31"
          title="Не забудьте поздравить с днём рождения 19.07.2024 Андреева Валентина Константиновича."
        />
        <NotificationItem
          button={{
            link: "/",
            text: "Пройти опрос",
          }}
          date="16 июня, 12:31"
          title="Назначена задача №444 «Пройти опрос удовлетворенности рабочим местом»"
        />
        <NotificationItem
          isRead
          button={{
            link: "/",
            text: "Перейти",
          }}
          date="12 июня, 12:31"
          title="По вашей заявке №222 «Перенести отпуск» поступило замечание руководителя. Ожидаем действие сотрудника."
        />
      </ScrollLayout>
    </div>
  );
};

export default memo(NotificationContent);

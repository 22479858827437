import React, { memo } from "react";

import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import useWidth from "@app/hooks/useWidth";

import MoneyValue, { MoneyValueSize } from "../../MoneyValue";

import { CHART_LEGEND_MAP, ChartLegendItemEnum } from "./config";

import * as s from "./ChartLegendItem.module.scss";

type Props = {
  /** Если true, значение будет размыто. */
  isVisible: boolean;
  /** Значение денег */
  value: number;
  /** ID легенды графика */
  id: ChartLegendItemEnum;
};

const ChartLegendItem: React.FC<Props> = ({ value, isVisible, id }) => {
  const { isDesktop } = useWidth();

  const { label, color } = CHART_LEGEND_MAP[id];

  return (
    <div className={s["root"]}>
      <div
        className={s["root__dot"]}
        style={{
          backgroundColor: color,
        }}
      />
      <Typography
        typographyType={
          isDesktop ? TypographyType.BODY_MD : TypographyType.CAPTION_EXTRA_SM
        }
        typographyWeight={TypographyWeight._500}
      >
        {label}
      </Typography>
      <MoneyValue
        isVisible={isVisible}
        size={MoneyValueSize.SMALL}
        value={value}
      />
    </div>
  );
};

export default memo(ChartLegendItem);

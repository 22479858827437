import clsx from "clsx";
import { memo, PropsWithChildren } from "react";

import ModalClose from "../ModalClose";

import * as s from "./ModalContent.module.scss";

export type ModalContentProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Мобильная версия. */
  isMobile: boolean;
  /** Обработчик закрытия модального окна. */
  onClose: () => void;
};

const ModalContent: React.FC<PropsWithChildren<ModalContentProps>> = ({
  className,
  isMobile,
  onClose,
  children,
}: PropsWithChildren<ModalContentProps>) => {
  return (
    <div className={clsx(s["modal-content"], className)}>
      {!isMobile && (
        <ModalClose className={s["modal-content__close"]} onClick={onClose} />
      )}

      {children}
    </div>
  );
};

export default memo(ModalContent);

import { motion, useInView } from "framer-motion";
import { memo, PropsWithChildren, useRef } from "react";

export type WidgetTransitionProps = {
  /** Дополнительные CSS классы для компонента. */
  className?: string;
};

const WidgetTransition: React.FC<PropsWithChildren<WidgetTransitionProps>> = ({
  className,
  children,
}: PropsWithChildren<WidgetTransitionProps>) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const isInView = useInView(ref, {
    amount: "some",
    once: true,
    margin: "0px",
  });

  return (
    <motion.div
      ref={ref}
      animate={{
        opacity: isInView ? 1 : 0,
        y: isInView ? 0 : 10,
        scale: isInView ? 1 : 0.98,
      }}
      className={className}
      initial={false}
      transition={{ duration: 0.5, ease: "linear" }}
    >
      {children}
    </motion.div>
  );
};

export default memo(WidgetTransition);

import { memo } from "react";

import IconBase, { type IconBaseProps } from "./IconBase";

const ArrowRight: React.FC<IconBaseProps> = (props: IconBaseProps) => {
  return (
    <IconBase {...props} height="24" viewBox="0 0 24 24" width="24">
      <path
        d="M20.3933 11.9988C20.3299 11.8351 20.2347 11.6856 20.1133 11.5588L13.4467 4.89212C13.3223 4.7678 13.1748 4.66919 13.0123 4.60191C12.8499 4.53463 12.6758 4.5 12.5 4.5C12.1449 4.5 11.8044 4.64105 11.5533 4.89212C11.429 5.01644 11.3304 5.16403 11.2631 5.32646C11.1958 5.48889 11.1612 5.66298 11.1612 5.83879C11.1612 6.19386 11.3023 6.53438 11.5533 6.78546L15.9533 11.1721H5.83333C5.47971 11.1721 5.14057 11.3126 4.89052 11.5626C4.64048 11.8127 4.5 12.1518 4.5 12.5055C4.5 12.8591 4.64048 13.1982 4.89052 13.4483C5.14057 13.6983 5.47971 13.8388 5.83333 13.8388H15.9533L11.5533 18.2255C11.4284 18.3494 11.3292 18.4969 11.2615 18.6594C11.1938 18.8218 11.1589 18.9961 11.1589 19.1721C11.1589 19.3481 11.1938 19.5224 11.2615 19.6849C11.3292 19.8474 11.4284 19.9948 11.5533 20.1188C11.6773 20.2438 11.8248 20.343 11.9872 20.4106C12.1497 20.4783 12.324 20.5132 12.5 20.5132C12.676 20.5132 12.8503 20.4783 13.0128 20.4106C13.1752 20.343 13.3227 20.2438 13.4467 20.1188L20.1133 13.4521C20.2347 13.3253 20.3299 13.1758 20.3933 13.0121C20.5267 12.6875 20.5267 12.3234 20.3933 11.9988Z"
        fill="currentColor"
      />
    </IconBase>
  );
};

export default memo(ArrowRight);

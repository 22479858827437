import clsx from "clsx";
import { memo } from "react";

import Tag, { TagAngle, TagColor, TagSize } from "@repo/ui/Tag";

import { UserStatus } from "./config";

import * as s from "./TabUserStatus.module.scss";

export type TabUserStatusProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
};

const TabUserStatus: React.FC<TabUserStatusProps> = ({
  className,
}: TabUserStatusProps) => {
  return (
    <div className={clsx(s["status"], className)}>
      <Tag
        angle={TagAngle.BOTTOM_LEFT}
        color={TagColor.TERTIARY}
        label={UserStatus.VACATION}
        size={TagSize.S}
      />

      <Tag
        angle={TagAngle.BOTTOM_LEFT}
        color={TagColor.SECONDARY}
        label={UserStatus.REMOTE}
        size={TagSize.S}
      />
    </div>
  );
};

export default memo(TabUserStatus);

import { action, makeObservable, observable } from "mobx";
import { type Area } from "react-easy-crop";

import { ILocalStore } from "@repo/data/store/interfaces/ILocalStore";
import MetaStore, { type Meta } from "@repo/data/store/models/MetaStore";
import GlobalUserStore from "@repo/data/store/single/GlobalUserStore";
import { getCroppedImg, readFile } from "@repo/ui/ImageCropper";

type PrivateFields = "_image" | "_cropParams";

class AvatarChangeStore implements ILocalStore {
  private readonly _metaStore = new MetaStore();

  private _image: string | null = null;
  private _cropParams: Area | null = null;

  get meta(): Meta {
    return this._metaStore.meta;
  }

  get image(): string | null {
    return this._image;
  }

  get cropParams(): Area | null {
    return this._cropParams;
  }

  constructor() {
    makeObservable<this, PrivateFields>(this, {
      _image: observable,
      _cropParams: observable,

      setImage: action,
      setCropParams: action.bound,
    });
  }

  async setImage(file: File): Promise<void> {
    const src = await readFile(file);

    this._image = src;
  }

  setCropParams(cropParams: Area): void {
    this._cropParams = cropParams;
  }

  async changeImage(): Promise<void> {
    this._metaStore.setLoadingStart();

    if (!this._image || !this._cropParams) {
      return;
    }

    try {
      const croppedImage = await getCroppedImg(this._image, this._cropParams);

      if (!croppedImage) {
        this._metaStore.setLoadedErrorMeta();
        return;
      }

      GlobalUserStore.getInstance().setAvatarUrl(croppedImage);

      this._metaStore.setLoadedSuccessMeta();
    } catch (e) {
      this._metaStore.setLoadedErrorMeta(e);
    }
  }

  destroy(): void {
    this._metaStore.destroy();
  }
}

export default AvatarChangeStore;

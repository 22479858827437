import { memo } from "react";

import IconBase, { type IconBaseProps } from "./IconBase";

const EyeCloseIcon: React.FC<IconBaseProps> = (props: IconBaseProps) => {
  return (
    <IconBase {...props} height="24" viewBox="0 0 24 24" width="24">
      <path
        d="M21.977 15.188a1.13 1.13 0 0 1-.415 1.539 1.126 1.126 0 0 1-1.539-.414l-1.58-2.766c-.883.555-1.833.992-2.829 1.3l.496 2.966a1.125 1.125 0 0 1-2.22.372l-.484-2.888a12.79 12.79 0 0 1-2.818 0l-.478 2.89a1.125 1.125 0 0 1-2.22-.374l.496-2.974a11.923 11.923 0 0 1-2.829-1.3l-1.58 2.774a1.127 1.127 0 1 1-1.954-1.125l1.727-3.014a14.98 14.98 0 0 1-1.623-1.717 1.125 1.125 0 0 1 1.749-1.414C5.379 10.905 8.01 13.125 12 13.125s6.62-2.22 8.124-4.082a1.125 1.125 0 0 1 1.75 1.414 14.98 14.98 0 0 1-1.624 1.717l1.727 3.014Z"
        fill="currentColor"
      />
    </IconBase>
  );
};

export default memo(EyeCloseIcon);

import { memo } from "react";

import { type IconBaseProps, ICON_ENTITIES, IconType } from "./components";

export type IconProps = {
  /** Тип иконки. */
  type: IconType;
} & IconBaseProps;

const Icon: React.FC<IconProps> = ({ type: iconType, ...props }: IconProps) => {
  const Comp = ICON_ENTITIES[iconType];

  return <Comp {...props} />;
};

export default memo(Icon);

import { memo } from "react";

import IconBase, { type IconBaseProps } from "./IconBase";

const ExtendIcon: React.FC<IconBaseProps> = (props: IconBaseProps) => {
  return (
    <IconBase {...props} height="24" viewBox="0 0 24 24" width="24">
      <path
        d="M16.4012 14.1841V7.76811c0-.19634-.0779-.38464-.2168-.52347-.1388-.13884-.3271-.21683-.5235-.21683H9.24501c-.19634 0-.38464.07799-.52347.21683-.13884.13883-.21683.32713-.21683.52347 0 .19634.07799.38464.21683.52347.13883.13883.32713.21683.52347.21683h4.62689L7.24064 15.1415c-.06886.0689-.12348.1506-.16075.2406s-.05645.1864-.05645.2838c0 .1967.07813.3853.2172.5244.13908.139.3277.2172.52438.2172.19668 0 .38531-.0782.52438-.2172l6.6312-6.63313v4.62693c0 .1963.078.3846.2169.5234.1388.1389.3271.2169.5234.2169.1964 0 .3847-.078.5235-.2169.1389-.1388.2168-.3271.2168-.5234Z"
        fill="currentColor"
      />
    </IconBase>
  );
};

export default memo(ExtendIcon);

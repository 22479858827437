import { useObserver } from "mobx-react-lite";

import GlobalThemeStore from "@repo/data/store/single/GlobalThemeStore";

function useTheme() {
  useObserver(() => {
    document.documentElement.setAttribute(
      "data-theme",
      GlobalThemeStore.getInstance().theme,
    );
  });
}

export default useTheme;

import { type UseTransitionStylesProps } from "@floating-ui/react";

export const TRANSITION_VARIANT: Record<string, UseTransitionStylesProps> = {
  DEFAULT: {
    initial: {
      opacity: 0,
      transform: "scale(0.8)",
    },
    duration: 300,
  },
  PHONE_WITH_OVERLAY: {
    initial: {
      opacity: 0,
      transform: "translateY(95%)",
    },
    open: {
      opacity: 1,
      transform: "translateY(0)",
    },
    common: {
      transformOrigin: "top",
    },
    duration: 350,
  },
} as const;

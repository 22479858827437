import clsx from "clsx";
import React, { memo } from "react";

import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import useWidth from "@app/hooks/useWidth";

import CalendarCell from "../CalendarCell/CalendarCell";

import * as s from "./CalendarCellWeekName.module.scss";

type Props = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Название дня недели */
  weekName: string;
};

const CalendarCellWeekName: React.FC<Props> = ({ className, weekName }) => {
  const { isDesktop } = useWidth();
  return (
    <CalendarCell className={clsx(s["root"], className)}>
      <Typography
        className={s["root__content"]}
        typographyType={
          isDesktop ? TypographyType.BODY_MD : TypographyType.CAPS_SM
        }
        typographyWeight={TypographyWeight._500}
      >
        {weekName}
      </Typography>
    </CalendarCell>
  );
};

export default memo(CalendarCellWeekName);

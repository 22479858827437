import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";

import { StoryType } from "@repo/data/store/single/GlobalStoriesStore";
import Button from "@repo/ui/Button";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import useModalStories from "@app/hooks/story/useModalStories";

import * as s from "./StoryItem.module.scss";

type Props = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Конфиг истории */
  item: StoryType;
};

const StoryItem: React.FC<Props> = ({ className, item }) => {
  const { handleOpen } = useModalStories();

  const handleClick = useCallback(() => {
    handleOpen(item);
  }, [handleOpen, item]);

  return (
    <Button.Default
      className={clsx(s["root"], className)}
      onClick={handleClick}
    >
      <div
        className={clsx(
          s["root__image"],
          item.highlight && s["root__image_highlight"],
        )}
        style={{
          backgroundImage: `url(${item.image})`,
        }}
      />
      <Typography
        className={s["root__title"]}
        typographyType={TypographyType.CAPTION}
        typographyWeight={TypographyWeight._500}
      >
        {item.title}
      </Typography>
    </Button.Default>
  );
};

export default observer(StoryItem);

import { useMergeRefs } from "@floating-ui/react";
import { ReactNode, forwardRef } from "react";

import { usePopoverContext } from "../../context";

type Props = {
  children: (
    props: { ref: ReturnType<typeof useMergeRefs> } & Record<string, unknown>,
  ) => ReactNode;
};

const PopoverTrigger = forwardRef<HTMLElement, Props>(
  ({ children, ...props }, propRef) => {
    const context = usePopoverContext();

    const ref = useMergeRefs([context.refs.setReference, propRef]);

    return children({
      ref,
      ...context.getReferenceProps(props),
      open: context.open,
    });
  },
);

PopoverTrigger.displayName = "PopoverTrigger";

export default PopoverTrigger;

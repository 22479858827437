import { Theme } from "../config/theme";

/**
 * Проверяет, является ли значение темой
 *
 * @param value - значение
 *
 * @returns Результат проверки
 */
function isThemeValue(value: string): value is Theme {
  return Object.values(Theme).includes(value as Theme);
}

export default isThemeValue;

import { Variants } from "framer-motion";

export const BELL_VARIANTS: Variants = {
  tap: { scale: 0.94 },
  ring: {
    rotate: [0, -10, 10, -10, 10, 0],
    transition: {
      duration: 0.5,
      ease: "easeInOut",
    },
  },
} as const;

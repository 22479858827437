import { memo } from "react";

import IconBase, { type IconBaseProps } from "./IconBase";

const Phone3dIcon: React.FC<IconBaseProps> = (props: IconBaseProps) => {
  return (
    <IconBase {...props} height="32" viewBox="0 0 32 32" width="32">
      <path
        d="M23.3689 2.03516H8.63088c-1.43594 0-2.6 1.16406-2.6 2.6V27.3572c0 1.4359 1.16406 2.6 2.6 2.6H23.3689c1.4359 0 2.6-1.1641 2.6-2.6V4.63516c0-1.43594-1.1641-2.6-2.6-2.6Z"
        fill="url(#a)"
      />
      <path
        d="M23.3689 2.03516H8.63088c-1.43594 0-2.6 1.16406-2.6 2.6V27.3572c0 1.4359 1.16406 2.6 2.6 2.6H23.3689c1.4359 0 2.6-1.1641 2.6-2.6V4.63516c0-1.43594-1.1641-2.6-2.6-2.6Z"
        fill="url(#b)"
      />
      <g filter="url(#c)">
        <path
          d="M9.20306 9.08047c0-.15913.06322-.31174.17574-.42426.11252-.11253.26513-.17574.42426-.17574h2.11304c.1591 0 .3117.06321.4242.17574.1126.11252.1758.26513.1758.42426v2.11303c0 .1591-.0632.3117-.1758.4242-.1125.1126-.2651.1758-.4242.1758H9.80306c-.15913 0-.31174-.0632-.42426-.1758-.11252-.1125-.17574-.2651-.17574-.4242V9.08047Z"
          fill="#FF9544"
        />
        <path
          d="M9.20306 9.08047c0-.15913.06322-.31174.17574-.42426.11252-.11253.26513-.17574.42426-.17574h2.11304c.1591 0 .3117.06321.4242.17574.1126.11252.1758.26513.1758.42426v2.11303c0 .1591-.0632.3117-.1758.4242-.1125.1126-.2651.1758-.4242.1758H9.80306c-.15913 0-.31174-.0632-.42426-.1758-.11252-.1125-.17574-.2651-.17574-.4242V9.08047Z"
          fill="url(#d)"
        />
      </g>
      <g filter="url(#e)">
        <path
          d="M14.344 9.08047c0-.15913.0632-.31174.1757-.42426.1126-.11253.2652-.17574.4243-.17574h2.112c.1591 0 .3117.06321.4243.17574.1125.11252.1757.26513.1757.42426v2.11303c0 .1591-.0632.3117-.1757.4242-.1126.1126-.2652.1758-.4243.1758h-2.112c-.1591 0-.3117-.0632-.4243-.1758-.1125-.1125-.1757-.2651-.1757-.4242V9.08047Z"
          fill="#38C6FF"
        />
      </g>
      <g filter="url(#f)">
        <path
          d="M19.4841 9.08047c0-.15913.0632-.31174.1757-.42426.1125-.11253.2651-.17574.4243-.17574h2.113c.1591 0 .3117.06321.4242.17574.1126.11252.1758.26513.1758.42426v2.11303c0 .1591-.0632.3117-.1758.4242-.1125.1126-.2651.1758-.4242.1758h-2.113c-.1592 0-.3118-.0632-.4243-.1758-.1125-.1125-.1757-.2651-.1757-.4242V9.08047Z"
          fill="#FFEC58"
        />
      </g>
      <g filter="url(#g)">
        <path
          d="M9.20306 14.9398c0-.1591.06322-.3117.17574-.4242.11252-.1125.26513-.1758.42426-.1758h2.11304c.1591 0 .3117.0633.4242.1758.1126.1125.1758.2651.1758.4242v2.112c0 .1592-.0632.3118-.1758.4243-.1125.1125-.2651.1757-.4242.1757H9.80306c-.15913 0-.31174-.0632-.42426-.1757-.11252-.1125-.17574-.2651-.17574-.4243v-2.112Z"
          fill="#38C6FF"
        />
      </g>
      <g filter="url(#h)">
        <path
          d="M9.20306 20.7992c0-.1591.06322-.3117.17574-.4242.11252-.1126.26513-.1758.42426-.1758h2.11304c.1591 0 .3117.0632.4242.1758.1126.1125.1758.2651.1758.4242v2.112c0 .1591-.0632.3118-.1758.4243-.1125.1125-.2651.1757-.4242.1757H9.80306c-.15913 0-.31174-.0632-.42426-.1757-.11252-.1125-.17574-.2652-.17574-.4243v-2.112Z"
          fill="#FF9544"
        />
        <path
          d="M9.20306 20.7992c0-.1591.06322-.3117.17574-.4242.11252-.1126.26513-.1758.42426-.1758h2.11304c.1591 0 .3117.0632.4242.1758.1126.1125.1758.2651.1758.4242v2.112c0 .1591-.0632.3118-.1758.4243-.1125.1125-.2651.1757-.4242.1757H9.80306c-.15913 0-.31174-.0632-.42426-.1757-.11252-.1125-.17574-.2652-.17574-.4243v-2.112Z"
          fill="url(#i)"
        />
      </g>
      <g filter="url(#j)">
        <path
          d="M14.344 14.9398c0-.1591.0632-.3117.1757-.4242.1126-.1125.2652-.1758.4243-.1758h2.112c.1591 0 .3117.0633.4243.1758.1125.1125.1757.2651.1757.4242v2.112c0 .1592-.0632.3118-.1757.4243-.1126.1125-.2652.1757-.4243.1757h-2.112c-.1591 0-.3117-.0632-.4243-.1757-.1125-.1125-.1757-.2651-.1757-.4243v-2.112Z"
          fill="#FFEC58"
        />
      </g>
      <g filter="url(#k)">
        <path
          d="M14.344 20.7992c0-.1591.0632-.3117.1757-.4242.1126-.1126.2652-.1758.4243-.1758h2.112c.1591 0 .3117.0632.4243.1758.1125.1125.1757.2651.1757.4242v2.112c0 .1591-.0632.3118-.1757.4243-.1126.1125-.2652.1757-.4243.1757h-2.112c-.1591 0-.3117-.0632-.4243-.1757-.1125-.1125-.1757-.2652-.1757-.4243v-2.112Z"
          fill="#38C6FF"
        />
      </g>
      <g filter="url(#l)">
        <path
          d="M19.4841 14.9398c0-.1591.0632-.3117.1757-.4242.1125-.1125.2651-.1758.4243-.1758h2.113c.1591 0 .3117.0633.4242.1758.1126.1125.1758.2651.1758.4242v2.112c0 .1592-.0632.3118-.1758.4243-.1125.1125-.2651.1757-.4242.1757h-2.113c-.1592 0-.3118-.0632-.4243-.1757-.1125-.1125-.1757-.2651-.1757-.4243v-2.112Z"
          fill="#FF9544"
        />
        <path
          d="M19.4841 14.9398c0-.1591.0632-.3117.1757-.4242.1125-.1125.2651-.1758.4243-.1758h2.113c.1591 0 .3117.0633.4242.1758.1126.1125.1758.2651.1758.4242v2.112c0 .1592-.0632.3118-.1758.4243-.1125.1125-.2651.1757-.4242.1757h-2.113c-.1592 0-.3118-.0632-.4243-.1757-.1125-.1125-.1757-.2651-.1757-.4243v-2.112Z"
          fill="url(#m)"
        />
      </g>
      <g filter="url(#n)">
        <path
          d="M19.4841 20.7992c0-.1591.0632-.3117.1757-.4242.1125-.1126.2651-.1758.4243-.1758h2.113c.1591 0 .3117.0632.4242.1758.1126.1125.1758.2651.1758.4242v2.112c0 .1591-.0632.3118-.1758.4243-.1125.1125-.2651.1757-.4242.1757h-2.113c-.1592 0-.3118-.0632-.4243-.1757-.1125-.1125-.1757-.2652-.1757-.4243v-2.112Z"
          fill="#FFEC58"
        />
      </g>
      <g filter="url(#o)">
        <path
          d="M23.688 5.59717h1.531V27.4412h-1.531V5.59717Z"
          fill="url(#p)"
        />
      </g>
      <g filter="url(#q)">
        <path
          d="M25.969 6.16016H6.03003v-1.525c0-.34144.06725-.67953.19791-.99498s.32218-.60207.56361-.8435c.24143-.24143.52806-.43295.8435-.56361.31545-.13066.65354-.19791.99498-.19791H23.37c.3415 0 .6796.06725.995.19791.3155.13066.6021.32218.8435.56361.2414.24143.433.52805.5636.8435.1307.31545.1979.65354.1979.99498l-.001 1.525Z"
          fill="url(#r)"
        />
        <path
          d="M25.969 6.16016H6.03003v-1.525c0-.34144.06725-.67953.19791-.99498s.32218-.60207.56361-.8435c.24143-.24143.52806-.43295.8435-.56361.31545-.13066.65354-.19791.99498-.19791H23.37c.3415 0 .6796.06725.995.19791.3155.13066.6021.32218.8435.56361.2414.24143.433.52805.5636.8435.1307.31545.1979.65354.1979.99498l-.001 1.525Z"
          fill="url(#s)"
        />
        <path
          d="M25.969 6.16016H6.03003v-1.525c0-.34144.06725-.67953.19791-.99498s.32218-.60207.56361-.8435c.24143-.24143.52806-.43295.8435-.56361.31545-.13066.65354-.19791.99498-.19791H23.37c.3415 0 .6796.06725.995.19791.3155.13066.6021.32218.8435.56361.2414.24143.433.52805.5636.8435.1307.31545.1979.65354.1979.99498l-.001 1.525Z"
          fill="url(#t)"
        />
        <path
          d="M25.969 6.16016H6.03003v-1.525c0-.34144.06725-.67953.19791-.99498s.32218-.60207.56361-.8435c.24143-.24143.52806-.43295.8435-.56361.31545-.13066.65354-.19791.99498-.19791H23.37c.3415 0 .6796.06725.995.19791.3155.13066.6021.32218.8435.56361.2414.24143.433.52805.5636.8435.1307.31545.1979.65354.1979.99498l-.001 1.525Z"
          fill="url(#u)"
        />
        <path
          d="M25.969 6.16016H6.03003v-1.525c0-.34144.06725-.67953.19791-.99498s.32218-.60207.56361-.8435c.24143-.24143.52806-.43295.8435-.56361.31545-.13066.65354-.19791.99498-.19791H23.37c.3415 0 .6796.06725.995.19791.3155.13066.6021.32218.8435.56361.2414.24143.433.52805.5636.8435.1307.31545.1979.65354.1979.99498l-.001 1.525Z"
          fill="url(#v)"
        />
      </g>
      <path
        d="M25.969 25.8784v1.479c0 .3415-.0672.6795-.1979.995-.1306.3154-.3222.6021-.5636.8435-.2414.2414-.528.4329-.8435.5636-.3154.1307-.6535.1979-.995.1979H8.63003c-.34144 0-.67953-.0672-.99498-.1979-.31544-.1307-.60207-.3222-.8435-.5636-.24143-.2414-.43295-.5281-.56361-.8435-.13066-.3155-.19791-.6535-.19791-.995v-1.479H25.969Z"
        fill="url(#w)"
      />
      <path
        d="M25.969 25.8784v1.479c0 .3415-.0672.6795-.1979.995-.1306.3154-.3222.6021-.5636.8435-.2414.2414-.528.4329-.8435.5636-.3154.1307-.6535.1979-.995.1979H8.63003c-.34144 0-.67953-.0672-.99498-.1979-.31544-.1307-.60207-.3222-.8435-.5636-.24143-.2414-.43295-.5281-.56361-.8435-.13066-.3155-.19791-.6535-.19791-.995v-1.479H25.969Z"
        fill="url(#x)"
      />
      <path
        d="M25.969 25.8784v1.479c0 .3415-.0672.6795-.1979.995-.1306.3154-.3222.6021-.5636.8435-.2414.2414-.528.4329-.8435.5636-.3154.1307-.6535.1979-.995.1979H8.63003c-.34144 0-.67953-.0672-.99498-.1979-.31544-.1307-.60207-.3222-.8435-.5636-.24143-.2414-.43295-.5281-.56361-.8435-.13066-.3155-.19791-.6535-.19791-.995v-1.479H25.969Z"
        fill="url(#y)"
      />
      <path
        d="M25.969 25.8784v1.479c0 .3415-.0672.6795-.1979.995-.1306.3154-.3222.6021-.5636.8435-.2414.2414-.528.4329-.8435.5636-.3154.1307-.6535.1979-.995.1979H8.63003c-.34144 0-.67953-.0672-.99498-.1979-.31544-.1307-.60207-.3222-.8435-.5636-.24143-.2414-.43295-.5281-.56361-.8435-.13066-.3155-.19791-.6535-.19791-.995v-1.479H25.969Z"
        fill="url(#z)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="a"
          x1="15.9999"
          x2="15.9999"
          y1="7.44116"
          y2="26.0032"
        >
          <stop stopColor="#62326D" />
          <stop offset="1" stopColor="#434486" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="b"
          x1="6.03088"
          x2="8.34388"
          y1="21.8162"
          y2="21.8782"
        >
          <stop offset=".031" stopColor="#412C4C" />
          <stop offset="1" stopColor="#412C4C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="d"
          x1="9.35906"
          x2="10.4061"
          y1="10.9575"
          y2="10.9575"
        >
          <stop offset=".333" stopColor="#FF945C" />
          <stop offset="1" stopColor="#FF945C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="i"
          x1="9.35906"
          x2="10.4061"
          y1="22.6752"
          y2="22.6752"
        >
          <stop offset=".333" stopColor="#FF945C" />
          <stop offset="1" stopColor="#FF945C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="m"
          x1="19.6411"
          x2="20.6881"
          y1="16.8158"
          y2="16.8158"
        >
          <stop offset=".333" stopColor="#FF945C" />
          <stop offset="1" stopColor="#FF945C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="p"
          x1="24.453"
          x2="24.453"
          y1="5.59717"
          y2="27.4412"
        >
          <stop stopColor="#674876" />
          <stop offset="1" stopColor="#555187" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="r"
          x1="16"
          x2="16"
          y1="2.03516"
          y2="6.16016"
        >
          <stop stopColor="#636AB6" />
          <stop offset="1" stopColor="#6C6EBE" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="s"
          x1="17.156"
          x2="17.156"
          y1="6.31616"
          y2="5.62816"
        >
          <stop stopColor="#6062B1" />
          <stop offset="1" stopColor="#6062B1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="t"
          x1="6.03103"
          x2="9.25003"
          y1="6.16016"
          y2="6.16016"
        >
          <stop offset=".136" stopColor="#484B86" />
          <stop offset="1" stopColor="#5E61AD" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="u"
          x1="25.969"
          x2="23.031"
          y1="6.16016"
          y2="6.16016"
        >
          <stop offset=".128" stopColor="#7277C8" />
          <stop offset="1" stopColor="#7277C8" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="w"
          x1="16"
          x2="16"
          y1="25.8784"
          y2="29.9564"
        >
          <stop stopColor="#6B4390" />
          <stop offset="1" stopColor="#76509E" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="x"
          x1="6.03103"
          x2="8.43803"
          y1="27.1284"
          y2="27.1284"
        >
          <stop offset=".164" stopColor="#4F3D6C" />
          <stop offset="1" stopColor="#6E4693" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="z"
          x1="15.688"
          x2="15.688"
          y1="25.5974"
          y2="26.1914"
        >
          <stop offset=".158" stopColor="#5E3D7F" />
          <stop offset="1" stopColor="#5E3D7F" stopOpacity="0" />
        </linearGradient>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="3.91299"
          id="c"
          width="3.91299"
          x="8.90306"
          y="8.18047"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="-.3" dy="-.3" />
          <feGaussianBlur stdDeviation=".2" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.913725 0 0 0 0 0.384314 0 0 0 0 0.129412 0 0 0 1 0" />
          <feBlend in2="shape" result="effect1_innerShadow_1151_4496" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx=".3" dy=".3" />
          <feGaussianBlur stdDeviation=".2" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.498039 0 0 0 0 0.160784 0 0 0 1 0" />
          <feBlend
            in2="effect1_innerShadow_1151_4496"
            result="effect2_innerShadow_1151_4496"
          />
        </filter>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="3.91299"
          id="e"
          width="3.91201"
          x="14.044"
          y="8.18047"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="-.3" dy="-.3" />
          <feGaussianBlur stdDeviation=".25" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.0980392 0 0 0 0 0.572549 0 0 0 0 0.866667 0 0 0 1 0" />
          <feBlend in2="shape" result="effect1_innerShadow_1151_4496" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx=".3" dy=".3" />
          <feGaussianBlur stdDeviation=".2" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.0745098 0 0 0 0 0.709804 0 0 0 0 1 0 0 0 1 0" />
          <feBlend
            in2="effect1_innerShadow_1151_4496"
            result="effect2_innerShadow_1151_4496"
          />
        </filter>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="3.91299"
          id="f"
          width="3.91299"
          x="19.1841"
          y="8.18047"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="-.3" dy="-.3" />
          <feGaussianBlur stdDeviation=".25" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.858824 0 0 0 0 0.752941 0 0 0 0 0.188235 0 0 0 1 0" />
          <feBlend in2="shape" result="effect1_innerShadow_1151_4496" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx=".3" dy=".3" />
          <feGaussianBlur stdDeviation=".2" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.968627 0 0 0 0 0.858824 0 0 0 0 0.223529 0 0 0 1 0" />
          <feBlend
            in2="effect1_innerShadow_1151_4496"
            result="effect2_innerShadow_1151_4496"
          />
        </filter>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="3.91201"
          id="g"
          width="3.91299"
          x="8.90306"
          y="14.0398"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="-.3" dy="-.3" />
          <feGaussianBlur stdDeviation=".25" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.0980392 0 0 0 0 0.572549 0 0 0 0 0.866667 0 0 0 1 0" />
          <feBlend in2="shape" result="effect1_innerShadow_1151_4496" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx=".3" dy=".3" />
          <feGaussianBlur stdDeviation=".2" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.0745098 0 0 0 0 0.709804 0 0 0 0 1 0 0 0 1 0" />
          <feBlend
            in2="effect1_innerShadow_1151_4496"
            result="effect2_innerShadow_1151_4496"
          />
        </filter>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="3.91201"
          id="h"
          width="3.91299"
          x="8.90306"
          y="19.8992"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="-.3" dy="-.3" />
          <feGaussianBlur stdDeviation=".2" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.913725 0 0 0 0 0.384314 0 0 0 0 0.129412 0 0 0 1 0" />
          <feBlend in2="shape" result="effect1_innerShadow_1151_4496" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx=".3" dy=".3" />
          <feGaussianBlur stdDeviation=".2" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.498039 0 0 0 0 0.160784 0 0 0 1 0" />
          <feBlend
            in2="effect1_innerShadow_1151_4496"
            result="effect2_innerShadow_1151_4496"
          />
        </filter>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="3.91201"
          id="j"
          width="3.91201"
          x="14.044"
          y="14.0398"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="-.3" dy="-.3" />
          <feGaussianBlur stdDeviation=".25" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.858824 0 0 0 0 0.752941 0 0 0 0 0.188235 0 0 0 1 0" />
          <feBlend in2="shape" result="effect1_innerShadow_1151_4496" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx=".3" dy=".3" />
          <feGaussianBlur stdDeviation=".2" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.968627 0 0 0 0 0.858824 0 0 0 0 0.223529 0 0 0 1 0" />
          <feBlend
            in2="effect1_innerShadow_1151_4496"
            result="effect2_innerShadow_1151_4496"
          />
        </filter>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="3.91201"
          id="k"
          width="3.91201"
          x="14.044"
          y="19.8992"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="-.3" dy="-.3" />
          <feGaussianBlur stdDeviation=".25" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.0980392 0 0 0 0 0.572549 0 0 0 0 0.866667 0 0 0 1 0" />
          <feBlend in2="shape" result="effect1_innerShadow_1151_4496" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx=".3" dy=".3" />
          <feGaussianBlur stdDeviation=".2" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.0745098 0 0 0 0 0.709804 0 0 0 0 1 0 0 0 1 0" />
          <feBlend
            in2="effect1_innerShadow_1151_4496"
            result="effect2_innerShadow_1151_4496"
          />
        </filter>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="3.91201"
          id="l"
          width="3.91299"
          x="19.1841"
          y="14.0398"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="-.3" dy="-.3" />
          <feGaussianBlur stdDeviation=".2" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.913725 0 0 0 0 0.384314 0 0 0 0 0.129412 0 0 0 1 0" />
          <feBlend in2="shape" result="effect1_innerShadow_1151_4496" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx=".3" dy=".3" />
          <feGaussianBlur stdDeviation=".2" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.498039 0 0 0 0 0.160784 0 0 0 1 0" />
          <feBlend
            in2="effect1_innerShadow_1151_4496"
            result="effect2_innerShadow_1151_4496"
          />
        </filter>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="3.91201"
          id="n"
          width="3.91299"
          x="19.1841"
          y="19.8992"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="-.3" dy="-.3" />
          <feGaussianBlur stdDeviation=".25" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.858824 0 0 0 0 0.752941 0 0 0 0 0.188235 0 0 0 1 0" />
          <feBlend in2="shape" result="effect1_innerShadow_1151_4496" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx=".3" dy=".3" />
          <feGaussianBlur stdDeviation=".2" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.968627 0 0 0 0 0.858824 0 0 0 0 0.223529 0 0 0 1 0" />
          <feBlend
            in2="effect1_innerShadow_1151_4496"
            result="effect2_innerShadow_1151_4496"
          />
        </filter>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="23.8442"
          id="o"
          width="3.53101"
          x="22.688"
          y="4.59717"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            result="effect1_foregroundBlur_1151_4496"
            stdDeviation=".5"
          />
        </filter>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="4.225"
          id="q"
          width="19.94"
          x="6.03003"
          y="2.03516"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy=".1" />
          <feGaussianBlur stdDeviation=".375" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.380392 0 0 0 0 0.407843 0 0 0 0 0.666667 0 0 0 1 0" />
          <feBlend in2="shape" result="effect1_innerShadow_1151_4496" />
        </filter>
        <radialGradient
          cx="0"
          cy="0"
          gradientTransform="matrix(-.65625 0 0 -3.17187 25.969 5.87816)"
          gradientUnits="userSpaceOnUse"
          id="v"
          r="1"
        >
          <stop stopColor="#6C6FB4" />
          <stop offset="1" stopColor="#6C6FB4" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          cx="0"
          cy="0"
          gradientTransform="matrix(0 3.09375 -1.625 0 24.313 25.5354)"
          gradientUnits="userSpaceOnUse"
          id="y"
          r="1"
        >
          <stop stopColor="#6B5094" />
          <stop offset="1" stopColor="#6B5094" stopOpacity="0" />
        </radialGradient>
      </defs>
    </IconBase>
  );
};

export default memo(Phone3dIcon);

import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { memo, PropsWithChildren } from "react";

import * as s from "./TabContent.module.scss";

export type TabContentProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Состояние скрываемой части. */
  isOpened?: boolean;
  /** Возможность скрыть содержимое. */
  isCollapsable?: boolean;
};

const TabContent: React.FC<PropsWithChildren<TabContentProps>> = ({
  className,
  isOpened = true,
  isCollapsable = false,
  children,
}: PropsWithChildren<TabContentProps>) => {
  return isCollapsable ? (
    <AnimatePresence initial={false}>
      {isOpened && (
        <motion.div
          animate={{ opacity: 1, height: "auto" }}
          className={clsx(s["content"], className)}
          exit={{ opacity: 0, height: 0, margin: 0 }}
          initial={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.3 }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  ) : (
    <div className={clsx(s["content"], className)}>{children}</div>
  );
};

export default memo(TabContent);
